import BaseApiService from "@/api/BaseApiService"
import proxy from "@/api/proxy"
import { CountryCodes } from "./Models/country-codes"
import { Countries } from "./Models/countries"

const v2LookupService = {
  GetCountryCodePrefix(): Promise<Countries[]> {
    return BaseApiService.getAsync(proxy.User, "User/Lookup/RestCountry")
  },
}
export default v2LookupService
