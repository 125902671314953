<template>
  <div :class="label === 'Phone number' ? 'phoneBox border-input-box' : 'border-input-box'" :style="transformWidth()" v-if="!disabled">
    <div v-if="!emailDuplicate && !phoneDuplicate" class="input-box" :class="validateValue() || showError ? 'error' : 'normal'">
      <p :class="!bigLabel ? 'label-small' : 'label-big'" v-on:click="$refs.myInput.focus()">
        {{ label }}
      </p>
      <input ref="myInput" class="input-bar" type="text" autocomplete="off" v-model="stringValue" :required="isRequired" @input="onChange()" @change="checkStringValid()" />
    </div>
    <div v-if="emailDuplicate || phoneDuplicate" class="input-box normal">
      <p :class="!bigLabel ? 'label-small' : 'label-big'" v-on:click="$refs.myInput.focus()">
        {{ label }}
      </p>
      <input ref="myInput" class="input-bar" type="text" autocomplete="off" v-model="stringValue" :required="isRequired" @input="onChange()" @change="checkStringValid()" />
    </div>
    <div :class="label === 'Phone number' ? 'move-left message' : showMessage() === '' ? '' : 'message'">
      <img v-if="validateValue() || (showError && isRequired && !emailDuplicate && !phoneDuplicate)" class="error-img" src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.5/cross.svg" />
      <img v-if="isRequired && !validateValue() && !showError" class="normal-img" src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.5/i_letter.svg" />
      <img v-if="!validateValue() && (emailDuplicate || phoneDuplicate)" class="blue-img" src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.5/i_letter.svg" />
      <p v-if="(!emailDuplicate && !phoneDuplicate) || validateValue()" :class="showError || validateValue() ? 'error-text' : 'normal-text'">
        {{ showMessage() }}
      </p>
      <p v-if="!validateValue() && (emailDuplicate || phoneDuplicate)" class="blue-text">
        {{ showMessage() }}
      </p>
    </div>
    <div v-if="!validateValue() && (emailDuplicate || phoneDuplicate)" style="height: 4px"></div>
  </div>
  <div class="border-input-box" :style="transformWidth()" v-if="disabled">
    <div class="input-box-disabled">
      <p :class="!bigLabel ? 'label-small' : 'label-big'">{{ label }}</p>
      <div class="input-bar-disabled">
        {{ stringValue }}
      </div>
    </div>
    <div :class="label === 'Phone number' ? 'move-left message' : showMessage() === '' ? '' : 'message'">
      <img v-if="isRequired && !validateValue() && !showError" class="normal-img" src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.5/exclamation_mark.svg" />
      <p :class="'normal-text'" class="disabled">
        {{ showMessage() }}
      </p>
    </div>
  </div>
</template>
<script lang="ts">
import ValidationService from "@/api/Security/validation-service"
import { Options, Vue } from "vue-class-component"

@Options({
  props: {
    label: String,
    inputValue: String,
    isRequired: Boolean,
    widthProcent: String,
    showError: Boolean,
    disabled: Boolean,
    emailDuplicate: Boolean,
    phoneDuplicate: Boolean,
    phoneCode: String,
  },
})
export default class InputFieldWithMessage extends Vue {
  label!: string
  inputValue!: string
  isRequired!: boolean
  widthProcent!: string
  showError!: boolean
  disabled!: boolean
  emailDuplicate!: boolean
  phoneDuplicate!: boolean
  phoneCode!: string
  stringValueValid = true
  stringValue = ""
  bigLabel = false

  mounted() {
    this.stringValue = this.inputValue
    if (this.stringValue === "" || this.stringValue === null) this.bigLabel = true
  }

  transformWidth() {
    var string = "width: " + this.widthProcent + ";"
    return string
  }

  onChange() {
    if (this.stringValue === "") {
      this.bigLabel = true
      this.stringValueValid = true
    } else this.bigLabel = false
    this.$emit("value", this.stringValue)
    if (!this.isRequired && this.validateValue() && this.stringValue.length > 0) this.$emit("error", true)
    else this.$emit("error", false)
  }

  validateValue(): boolean {
    if (this.isRequired) {
      if (this.stringValue !== "" && this.stringValue !== null) {
        if (this.label === "First name" || this.label === "Last name") {
          if (/\d/.test(this.stringValue)) return true
        }
        if (this.label === "Email") {
          if (!/\S+@\S+\.\S+/.test(this.stringValue) && !this.stringValueValid) return true
        }
        if (this.label === "Phone number") {
          if (!/^\d+$/.test(this.stringValue) || !this.stringValueValid) return true
        }
      }
    } else if (!this.isRequired && this.stringValue.length > 0) {
      if (this.label === "Email") {
        if (!/\S+@\S+\.\S+/.test(this.stringValue) && !this.stringValueValid) return true
      }
      if (this.label === "Phone number") {
        if (!/^\d+$/.test(this.stringValue) || !this.stringValueValid) return true
      }
    }
    return false
  }

  async checkStringValid() {
    if (this.stringValue.length === 0) this.stringValueValid = true
    else if (this.label === "Email") this.stringValueValid = false
    else if (this.stringValue.length < 4) this.stringValueValid = false
    else if (this.label === "Phone number" && /^\d+$/.test(this.stringValue) && (this.phoneCode !== undefined || this.phoneCode !== null || this.phoneCode !== "")) {
      var response = await ValidationService.ValidatePhoneNumber(this.phoneCode.replace("+", "%2B") + this.stringValue)
      this.stringValueValid = response.IsValid
    }
  }

  showMessage(): string {
    if (!this.validateValue() && this.emailDuplicate) return "This email matches one in another field. Consider using a different email address."
    if (!this.validateValue() && this.phoneDuplicate) return "This phone number matches one in another field. Consider using a different phone number."
    if (this.isRequired) {
      if (this.showError && this.stringValue === "") return "Please fill in " + this.label.toLowerCase()
      if (this.validateValue()) return "The " + this.label.toLowerCase() + " is not valid"
      if (this.isRequired) return "Required information"
    }
    if (!this.isRequired) {
      if (this.validateValue()) return "The " + this.label.toLowerCase() + " is not valid"
    }
    return ""
  }
}
</script>
<style lang="scss">
.phoneBox {
  max-width: 208px;
  margin-right: 12px;
}
.border-input-box {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  .input-box {
    position: relative;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 12px;
    padding: 8px 16px;
    height: 38px;
    background: $ag-cream;
    display: flex;
    justify-content: center;
    cursor: pointer;
    .label-small {
      color: $ag-dark-grey;
      margin: 0;
      font-family: $font;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      transition: all 0.3s;
    }
    .label-big {
      position: absolute;
      z-index: 1;
      left: 18px;
      color: $ag-dark-grey;
      margin: 0;
      font-family: $font;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      transition: all 0.3s;
      cursor: text;
    }
    .input-bar {
      padding: 0 !important;
      width: 100%;
      background: $ag-cream;
      border: none;
      outline: none;
      color: $ag-black;
      font-family: $font;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      height: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .input-box:hover {
    border: 1px solid $ag-light-grey;
    background: $ag-white;
    .input-bar {
      background: $ag-white;
    }
  }
  .input-box:focus-within {
    border: 1px solid $ag-light-grey;
    background: $ag-white;
    .input-bar {
      background: $ag-white;
    }
  }
  .input-box-disabled {
    position: relative;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 12px;
    padding: 8px 16px;
    height: 38px;
    background: $ag-cream;
    display: flex;
    justify-content: center;
    border: 1px solid $ag-medium-grey;
    .label-small {
      color: $ag-medium-grey;
      margin: 0;
      font-family: $font;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      transition: all 0.3s;
    }
    .label-big {
      position: absolute;
      z-index: 1;
      left: 18px;
      color: $ag-medium-grey;
      margin: 0;
      font-family: $font;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      transition: all 0.3s;
    }
    .input-bar-disabled {
      text-align: left;
      width: 100%;
      background: $ag-cream;
      border: none;
      outline: none;
      color: $ag-medium-grey;
      font-family: $font;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      height: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.disabled {
  color: $ag-medium-grey;
}
.error {
  border: 1px solid $ag-red;
}
.normal {
  border: 1px solid transparent;
}
.move-left {
  margin-left: -126px;
  width: 160% !important;
}
.message {
  display: inline-flex;
  gap: 4px;
  align-items: center;
  height: 12px;
  width: 118%;

  .error-img {
    filter: invert(45%) sepia(67%) saturate(620%) hue-rotate(311deg) brightness(95%) contrast(89%);
    height: 12px;
    width: 12px;
  }
  .normal-img {
    height: 12px;
    width: 12px;
  }
  .blue-img {
    filter: invert(54%) sepia(71%) saturate(361%) hue-rotate(179deg) brightness(102%) contrast(102%);
    height: 12px;
    width: 12px;
  }
  .error-text {
    color: $ag-red;
    font-family: $font;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin: 0;
  }
  .normal-text {
    color: $ag-black;
    font-family: $font;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
  }
  .blue-text {
    padding-top: 12px;
    color: $ag-blue;
    font-family: $font;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
    max-width: 75%;
  }
}
</style>
