import BaseApiService from "@/api/BaseApiService"
import proxy from "@/api/proxy"

const MessageServiceV1 = {
  getMessageHistory(trackingId: string): Promise<any> {
    return BaseApiService.getAsync(proxy.Communication, `MessageHistory/${trackingId}`)
  },
}

export default MessageServiceV1
