<template>
  <div class="main-page">
    <div v-if="type == 'succes'" class="succes">
      <img src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.5/check.svg" class="succes-filter" />
      <p v-if="!!text">{{ text }}</p>
    </div>
    <div v-if="type == 'alert'" class="alert">
      <img src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.5/exclamation_mark.svg" class="alert-filter" />
      <p v-if="!!text">{{ text }}</p>
    </div>
    <div v-if="type == 'error'" class="error">
      <img src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.5/cross.svg" class="error-filter" />
      <p v-if="!!text">{{ text }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"

@Options({
  props: {
    text: String,
    type: String,
  },
})
export default class LabelBar extends Vue {
  text!: string
  type!: string
}
</script>

<style lang="scss">
.main-page {
  .succes {
    background: $ag-light-green;
    width: fit-content;
    height: 14px;
    padding: 8px 12px 8px 12px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid $ag-green;
    box-shadow: 1px 2px 10px 0px rgba(34, 34, 34, 0.05);
    .succes-filter {
      filter: invert(38%) sepia(20%) saturate(985%) hue-rotate(109deg) brightness(91%) contrast(94%);
      width: 20px;
      height: 20px;
    }
    p {
      color: $ag-dark-green;
      font-family: $font;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      margin: 0;
    }
  }
  .error {
    background: $ag-light-red;
    width: fit-content;
    height: 14px;
    padding: 8px 12px 8px 12px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid $ag-red;
    box-shadow: 1px 2px 10px 0px rgba(34, 34, 34, 0.05);
    .error-filter {
      filter: invert(37%) sepia(13%) saturate(2584%) hue-rotate(314deg) brightness(82%) contrast(90%);
      width: 20px;
      height: 20px;
    }
    p {
      color: $ag-dark-red;
      font-family: $font;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      margin: 0;
    }
  }
  .alert {
    background: $ag-light-yellow;
    width: fit-content;
    height: 14px;
    padding: 8px 12px 8px 12px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid $ag-yellow;
    box-shadow: 1px 2px 10px 0px rgba(34, 34, 34, 0.05);
    .alert-filter {
      filter: invert(39%) sepia(50%) saturate(384%) hue-rotate(3deg) brightness(98%) contrast(90%);
      width: 20px;
      height: 20px;
    }
    p {
      color: $ag-dark-yellow;
      font-family: $font;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      margin: 0;
    }
  }
}
</style>
