<template>
  <div>
    <div style="display: flex; justify-content: center; width: 100%">
      <div class="BottomBar" v-if="isMobile == false">
        <div class="TextContainer">
          <b>{{ headerText }}</b>
          <p :class="bodyTextRed ? 'red' : ''">{{ bodyText }}</p>
        </div>
        <div class="buttonWrapper">
          <button v-if="active" class="button" v-on:click="confirm()">Send updates</button>
          <div v-if="!active" class="button-not-active" v-on:click="confirmNotReady()">Send updates</div>
        </div>
      </div>
    </div>
    <div class="bottomBar-mobile" v-if="isMobile == true">
      <div class="div-button" v-if="active" v-on:click="confirm">
        <b>
          {{ headerText }}
          <img v-if="withArrow" width="20" height="20" style="align-self: center; filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(296deg) brightness(108%) contrast(103%)" src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/arrow_1_5px/right.png" />
        </b>
        <p :class="bodyTextRed ? 'red' : ''">{{ bodyText }}</p>
      </div>
      <div class="div-button-not-active" v-if="!active" v-on:click="confirmNotReady()">
        <b>{{ headerText }}</b>
        <p :class="bodyTextRed ? 'red' : ''">{{ bodyText }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"

@Options({
  props: {
    headerText: String,
    bodyText: String,
    bodyTextRed: Boolean,
    active: Boolean,
    isMobile: Boolean,
    withArrow: Boolean,
  },
})
export default class FloatingBottomBar extends Vue {
  headerText!: string
  bodyText!: string
  bodyTextRed!: boolean
  active!: boolean
  isMobile!: boolean
  withArrow!: boolean

  confirm() {
    this.$emit("updateConfirm")
  }
  confirmNotReady() {
    this.$emit("updateConfirmNotReady")
  }
}
</script>

<style lang="scss">
.bottomBar-mobile {
  width: 100%;
  bottom: 50px;
  position: fixed;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  .div-button {
    border-radius: 16px;
    background: $ag-black;
    width: 82%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .div-button-not-active {
    border-radius: 16px;
    background: $ag-light-grey;
    width: 82%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  b {
    margin: 0;
    color: $ag-white;
    font-family: $font;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    gap: 8px;
  }
  p {
    margin: 0;
    color: $ag-white;
    font-family: $font;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
  .red {
    color: $ag-red;
  }
}
.BottomBar {
  width: 1360px;
  height: 104px;
  max-height: 104px;
  background: $ag-white;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  z-index: 10;
  border: solid;
  border-color: $ag-light-grey;
  border-radius: 12px;
  border-width: 1px;
  z-index: 3;
  filter: drop-shadow(0px 2px 10px rgba(34, 34, 34, 0.1));
}
.TextContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  padding: 24px 80px 24px 40px;
  b {
    margin: 0;
    color: $ag-black;
    font-family: $font;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  p {
    margin: 0;
    color: $ag-medium-grey;
    font-family: $font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .red {
    color: $ag-red;
    font-weight: 500;
  }
}
.buttonWrapper {
  padding: 24px 40px 24px 80px;
  .button {
    height: 56px;
    width: fit-content;
    border-radius: 16px;
    border: 1px solid transparent;
    padding: 18px 16px 18px 16px;
    background: $ag-black;
    color: $ag-white;
    font-family: $font;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    cursor: pointer;
  }
  .button-not-active {
    width: fit-content;
    border-radius: 16px;
    padding: 18px 16px 18px 16px;
    background: $ag-light-grey;
    border: 1px solid transparent;
    color: $ag-white;
    font-family: $font;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
  }
  .button:hover {
    opacity: 0.9;
  }
}
</style>
