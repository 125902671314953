<template>
  <div style="position: relative" v-on:click="insideClick = true" v-if="!disabled">
    <div v-if="showError" :class="[codePickerShown ? 'showBox error' : 'dontShowBox error']" v-on:click="toggleCodePicker()">
      <div>
        <p :class="currentCode !== '' || newCode !== '0' ? 'grey-small' : 'grey'">Country</p>
        <p class="black" v-if="currentCode !== '0' || newCode !== '0'">
          {{ currentCode === "0" ? newCode : currentCode }}
        </p>
      </div>
      <img class="small-img" :src="codePickerShown ? 'https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/up.svg' : 'https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/down.svg'" />
    </div>
    <div v-if="!showError" :class="[codePickerShown ? 'showBox' : 'dontShowBox']" v-on:click="toggleCodePicker()">
      <div>
        <p :class="currentCode !== '' || newCode !== '0' ? 'grey-small' : 'grey'">Country</p>
        <p class="black" v-if="currentCode !== '0' || newCode !== '0'">
          {{ currentCode === "0" ? newCode : currentCode }}
        </p>
      </div>
      <img class="small-img" :src="codePickerShown ? 'https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/up.svg' : 'https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/down.svg'" />
    </div>
  </div>
  <div class="code-picker-shown" :style="showAbove ? 'bottom: 104px' : 'top: 158px;'" v-if="codePickerShown && !isMobile" v-on:click="insideClick = true">
    <div class="search-bar">
      <input class="search" type="text" placeholder="Search country code" v-model="search" />
      <img class="search-img" src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/magnifier.svg" />
    </div>
    <div class="br-line"></div>
    <div class="list-entries">
      <div v-for="(country, index) in filteredCountryCodes()" :key="index" v-on:click="pickCountryCode(country)">
        <div class="selected-country" v-if="(currentCode === '+' + country.CallingCode && newCode === '0') || (newCode === '+' + country.CallingCode && currentCode === '0')">
          <div class="name">
            <p class="selected">{{ country.nativeCountry }}</p>
            <p class="selected-12px">{{ country.country }}</p>
          </div>
          <p class="selected">+{{ country.CallingCode }}</p>
        </div>
        <div class="country" v-if="(currentCode !== '+' + country.CallingCode && newCode === '0') || (newCode !== '+' + country.CallingCode && currentCode === '0')">
          <div class="name">
            <p class="black">{{ country.nativeCountry }}</p>
            <p class="grey-small">{{ country.country }}</p>
          </div>
          <p class="black">+{{ country.CallingCode }}</p>
        </div>
      </div>
    </div>
    <div style="height: 120px"></div>
  </div>
  <div style="position: relative" v-if="disabled">
    <div class="disabledShowBox">
      <div>
        <p class="grey-small" style="color: #a6a6a6">Country</p>
        <p class="black" v-if="currentCode !== '0' || newCode !== '0'" style="color: #a6a6a6">
          {{ currentCode === "0" ? newCode : currentCode }}
        </p>
      </div>
      <img class="small-img" style="filter: invert(75%) sepia(0%) saturate(24%) hue-rotate(212deg) brightness(89%) contrast(89%)" src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/down.svg" />
    </div>
  </div>
</template>
<script lang="ts">
import { Countries } from "@/api/User/V2Lookup/Models/countries"
import { CountryCodes } from "@/api/User/V2Lookup/Models/country-codes"
import v2LookupService from "@/api/User/V2Lookup/v2Lookup-service"

import { Options, Vue } from "vue-class-component"

@Options({
  props: {
    currentCode: String,
    showAbove: Boolean,
    disabled: Boolean,
    isMobile: Boolean,
    country: String,
    showError: Boolean,
  },
})
export default class CountryCodePicker extends Vue {
  countryCodes: CountryCodes[] = []
  countries: Countries[] = []
  currentCode!: string
  country!: string
  disabled!: boolean
  showAbove!: boolean
  isMobile!: boolean
  showError!: boolean
  newCode = "0"
  search = ""
  searchCountryCodes: CountryCodes[] = []
  codePickerShown = false
  insideClick = true

  onClick() {
    if (!this.isMobile) {
      if (!this.insideClick) {
        if (this.codePickerShown) this.$emit("closeCodePicker")
        this.search = ""
        this.codePickerShown = false
      } else {
        this.insideClick = false
      }
    }
  }

  toggleCodePicker() {
    if (this.codePickerShown) this.$emit("closeCodePicker")
    this.insideClick = true
    this.codePickerShown = !this.codePickerShown
    if (this.codePickerShown) this.$emit("openCurrent")
  }

  mounted(): void {
    this.getCountryCode()
    document.addEventListener("click", this.onClick)
  }

  async getCountryCode() {
    this.countries = await v2LookupService.GetCountryCodePrefix()
    this.countries.forEach((x) => {
      if (x.CallingCodes) {
        this.countryCodes.push({
          CallingCode: x.CallingCodes[0],
          country: x.Name,
          nativeCountry: x.NativeName,
        })
      }
    })
    if (this.currentCode === "0" && this.newCode === "0") {
      const foundCountry = this.countryCodes.find((country) => country.country === this.country)
      if (foundCountry) {
        this.newCode = "+" + foundCountry.CallingCode
      }
    }
  }

  pickCountryCode(country: CountryCodes) {
    var returnVal = "+" + country.CallingCode
    this.$emit("updateCode", returnVal)
    this.search = ""
    this.codePickerShown = false
  }

  filteredCountryCodes() {
    const searchText = this.search.toLowerCase()
    return this.countryCodes.filter((country) => country.nativeCountry.toLowerCase().includes(searchText) || country.country.toLowerCase().includes(searchText) || country.CallingCode.toLowerCase().includes(searchText))
  }
}
</script>
<style lang="scss">
html,
body {
  scroll-behavior: smooth;
  scroll-behavior: cubic-bezier(0.42, 0, 0.58, 1);
}
.list-entries::-webkit-scrollbar {
  width: 8px;
  flex-shrink: 0;
  border-radius: 12px;
}

.list-entries::-webkit-scrollbar-thumb {
  background: $ag-medium-grey;
  height: 33px;
  border-radius: 100px;
}

.list-entries::-webkit-scrollbar-track {
  background: $ag-cream;
}
.dropdown-enter-active {
  transition: height 0.2s ease-in;
}
.dropdown-leave-active {
  transition: height 0.2s ease-out;
}
.dropdown-enter,
.dropdown-leave-to {
  height: 0;
  padding: 0px 16px;
  display: none;
}
.error {
  border: 1px solid $ag-red !important;
}
.showBox {
  width: 84px;
  height: 38px;
  border-radius: 12px;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid $ag-light-grey;
  background: $ag-white;
}
.dontShowBox {
  width: 84px;
  height: 38px;
  border-radius: 12px;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  background: $ag-cream;
  z-index: 5;
}
.disabledShowBox {
  width: 84px;
  height: 38px;
  border-radius: 12px;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid $ag-medium-grey;
  background: $ag-cream;
}
.dontShowBox:hover {
  border: 1px solid $ag-light-grey;
  background: #fff;
}
.small-img {
  width: 16px;
  height: 16px;
}
.black {
  font-family: $font;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  color: #222;
}
.grey {
  color: $ag-dark-grey;
  margin: 0;
  font-family: $font;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.code-picker-shown {
  width: 292px;
  height: 460px;
  background: #fff;
  border: 1px solid $ag-light-grey;
  border-radius: 12px;
  padding: 14px 0 0 16px;
  position: absolute;
  z-index: 100;
  overflow: hidden;
  filter: drop-shadow(0px 2px 10px rgba(34, 34, 34, 0.1));
}
.search-bar {
  width: 247px;
  height: 40px;
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 12px;
  background: #f2f2f2;
  overflow: hidden;
  padding: 0 16px;
}
.search {
  width: 100%;
  outline: none;
  border: none;
  margin: 0;
  color: #222;
  background: #f2f2f2;
  font-family: $font;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.search::placeholder {
  color: #a6a6a6;
}
.search-img {
  width: 20px;
  height: 20px;
  filter: invert(32%) sepia(59%) saturate(0%) hue-rotate(162deg) brightness(92%) contrast(86%);
}
.br-line {
  margin-top: 16px;
  margin-left: -16px;
  width: 120%;
  height: 1px;
  background: #dfdfdf;
}
.list-entries {
  display: flex;
  flex-direction: column;
  height: 402px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.country {
  width: 97%;
  display: inline-flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 8px;
  padding-top: 8px;
  border-bottom: 1px solid $ag-light-grey;
  cursor: pointer;
}
.country:hover {
  opacity: 0.75;
}
.name {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
}
.black {
  padding-right: 8px;
  font-family: $font;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  color: $ag-black;
}
.grey-small {
  font-family: $font;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
  color: $ag-dark-grey;
}
.selected-country {
  width: 97%;
  display: inline-flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 8px;
  padding-top: 8px;
  border-bottom: 1px solid $ag-light-grey;
  cursor: not-allowed;
}
.selected {
  padding-right: 8px;
  font-family: $font;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  color: #a6a6a6;
}
.selected-12px {
  font-family: $font;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
  color: #a6a6a6;
}
</style>
