const AGtoken = "AGTOKEN"
const JourneyList = "JOURNEYLIST"
const DeletedJourneys = "DELETEDJOURNEYS"
const Group = "GROUP"
const PhoneCode = "PHONECODE"
const PhoneNbr = "PHONENBR"
const HotelId = " HOTELID"

const localstorage = {
  getToken() {
    // return sessionStorage.getItem(AGtoken);
    return "akKqw23J0odIuJ5lekXPdvibpgbHAT+Tx5rRfxpKHTA="
  },
  saveToken(token: string) {
    return sessionStorage.setItem(AGtoken, token)
  },
  saveHotelId(hotelId: string) {
    return sessionStorage.setItem(HotelId, hotelId)
  },
  getHotelId() {
    return sessionStorage.getItem(HotelId)
  },
  getJourney() {
    return sessionStorage.getItem(JourneyList)
  },
  saveJourney(journey: string) {
    return sessionStorage.setItem(JourneyList, journey)
  },
  getDeleted() {
    return sessionStorage.getItem(DeletedJourneys)
  },
  saveDeleted(deleted: string) {
    return sessionStorage.setItem(DeletedJourneys, deleted)
  },
  GetGroup() {
    return sessionStorage.getItem(Group)
  },
  SaveGroup(group: string) {
    return sessionStorage.setItem(Group, group)
  },
  GetPhoneCode() {
    return sessionStorage.getItem(PhoneCode)
  },
  SavePhoneCode(phonecode: string) {
    return sessionStorage.setItem(PhoneCode, phonecode)
  },
  GetPhoneNbr() {
    return sessionStorage.getItem(PhoneNbr)
  },
  SavePhoneNbr(phonenbr: string) {
    return sessionStorage.setItem(PhoneNbr, phonenbr)
  },
  clear() {
    return sessionStorage.clear()
  },
}
export default localstorage
