import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import HomeView from "../views/HomeView.vue"
import GroupBookingView from "../views/GroupBookingView.vue"
import ConfirmGroupBookingView from "../views/ConfirmGroupBookingView.vue"
import LinkExpiredView from "../views/LinkExpiredView.vue"
import ViewEmailInBrowserView from "../views/ViewEmailInBrowserView.vue"
import WelcomePageView from "@/views/WelcomePageView.vue"
import YourDoneView from "@/views/YourDoneView.vue"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/groupbooking/:groupId/:hotelId",
    name: "WelcomePage",
    component: WelcomePageView,
  },
  {
    path: "/reservationlist/:groupId/:hotelId",
    name: "GroupBooking",
    component: GroupBookingView,
    props: true,
  },
  {
    path: "/LinkExpired",
    name: "LinkExpired",
    component: LinkExpiredView,
  },
  {
    path: "/GroupBookingFinalized",
    name: "GroupBookingFinalized",
    component: YourDoneView,
  },

  {
    path: "/email/:trackingId",
    name: "EmailInBrowser",
    component: ViewEmailInBrowserView,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
