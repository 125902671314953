<template>
  <div>
    <div v-html="emailHtml"></div>
  </div>
</template>

<script lang="ts">
import MessageServiceV1 from "@/api/Communication/MessageHistory/MessageHistoryService"

import { Options, Vue } from "vue-class-component"

@Options({
  components: {},
})
export default class ViewEmailInBrowserView extends Vue {
  emailHtml = ""
  trackingId = ""
  async mounted() {
    this.trackingId = this.$route.params.trackingId as string
    var html = await MessageServiceV1.getMessageHistory(this.trackingId)
    this.emailHtml = html
  }
}
</script>

<style lang="scss"></style>
