<template>
  <div>
    <div class="MainPagePc" v-if="isMobile == false">
      <h3 style="margin-top: 29px">Reservation update</h3>
      <h4>{{ hotelName }}</h4>
      <div style="width: 100%; height: 1px; background: rgba(34, 34, 34, 0.1); margin-top: 16px; margin-bottom: 40px" />

      <FloatingBottomBar
        :headerText="isReservationDone() + ' out of ' + JourneyList.length + ' updated'"
        :bodyText="isReservationDone() !== JourneyList.length ? 'Update the remaining reservations' : 'Confirm & send updates'"
        :bodyTextRed="confirmNotReady && isReservationDone() !== JourneyList.length"
        :active="isReservationDone() == JourneyList.length"
        :isMobile="false"
        @updateConfirm="confirmPressed()"
        @updateConfirmNotReady="confirmNotReadyPressed()" />

      <div class="tableborder">
        <div style="display: flex; flex-direction: column; align-items: flex-start; gap: 8px; width: 1280px; margin-bottom: 40px">
          <h5>Reservations list</h5>
          <p style="width: 400px">Fill out the information for each reservation, and make your upcoming stay as smooth as possible.</p>
        </div>

        <table class="booking-table">
          <div v-for="(journey, index) of JourneyList" :key="index" :style="{ zIndex: showCodePicker[index] ? '5' : '1' }">
            <tr class="table-row">
              <div class="info-row">
                <div style="display: inline-flex; align-items: center; gap: 40px">
                  <h1>Reservation {{ index + 1 }}</h1>
                  <div style="display: inline-flex; align-items: center; gap: 8px" v-if="!GroupBookingList[0].HideGuestRoom">
                    <img width="18" height="18" src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/double_bed.svg" />
                    <p>{{ findRoomType(journey.RoomType) }}</p>
                  </div>
                  <div style="display: inline-flex; align-items: center; gap: 8px" v-if="!GroupBookingList[0].HideNumberOfGuest">
                    <img width="18" height="18" src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/person.svg" />
                    <p>{{ journey.NumberOfGuest }}</p>
                  </div>
                  <div style="display: inline-flex; align-items: center; gap: 8px">
                    <img width="18" height="18" src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/clock.svg" />
                    <p>{{ formatDate(journey.StartDate) }}</p>
                    <img width="14" height="14" style="margin: 0 8px" src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/arrow_1_5px/right.png" />
                    <p>{{ formatDate(journey.EndDate) }}</p>
                  </div>
                </div>
                <div style="display: inline-flex; gap: 16px; align-items: center">
                  <label-bar v-if="reservationDone(journey)" :type="'succes'" :text="'Filled out'"></label-bar>
                </div>
              </div>

              <div style="height: 1px; width: 96.25%; background: #dfdfdf"></div>

              <div class="input-row">
                <input-field-with-message
                  :label="'First name'"
                  :inputValue="journey.FirstName"
                  :widthProcent="'22.8%'"
                  :isRequired="GroupBookingList[0].FirstNameRequired"
                  :emailDuplicate="false"
                  :phoneDuplicate="false"
                  :showError="redBorder(journey.FirstName, index)"
                  @value="handleInputChange(journey, 'FirstName', $event)"></input-field-with-message>
                <input-field-with-message
                  :label="'Last name'"
                  :inputValue="journey.LastName"
                  :widthProcent="'22.8%'"
                  :isRequired="GroupBookingList[0].LastNameRequired"
                  :emailDuplicate="false"
                  :phoneDuplicate="false"
                  :showError="redBorder(journey.LastName, index)"
                  @value="handleInputChange(journey, 'LastName', $event)"></input-field-with-message>
                <input-field-with-message
                  :label="'Email'"
                  :inputValue="journey.Email"
                  :widthProcent="'22.8%'"
                  :emailDuplicate="duplicateEmail(journey.Email, index)"
                  :phoneDuplicate="false"
                  :isRequired="GroupBookingList[0].EmailRequired"
                  :showError="redBorder(journey.Email, index, 'Email')"
                  @value="handleInputChange(journey, 'Email', $event)"
                  @error="handleError(journey, 'Email', $event)"></input-field-with-message>

                <div style="display: inline-flex; gap: 8px; width: fit-content">
                  <div>
                    <country-code-picker
                      :showError="redBorder(phoneCodes[index], index)"
                      :currentCode="phoneCodes[index]"
                      :showAbove="codePickerShowAbove(index)"
                      :country="hotelCountry"
                      :isMobile="false"
                      @openCurrent="showCurrentCodePicker(index)"
                      @updateCode="onUpdateCode($event)"
                      @closeCodePicker="closeCodePicker()" />
                  </div>
                  <input-field-with-message
                    :label="'Phone number'"
                    :inputValue="phonenumbers[index]"
                    :widthProcent="'100%'"
                    :isRequired="GroupBookingList[0].PhoneNumberRequired"
                    :emailDuplicate="false"
                    :phoneDuplicate="duplicatePhone(phonenumbers[index], index)"
                    :showError="redBorder(phonenumbers[index], index, 'PhoneNumber')"
                    :phoneCode="phoneCodes[index]"
                    @value="handleInputChange(journey, 'PhoneNumber', $event)"
                    @error="handleError(journey, 'PhoneNumber', $event)"></input-field-with-message>
                </div>
              </div>
            </tr>
            <div style="height: 16px"></div>
          </div>
        </table>
      </div>

      <apply-phone-code-box v-if="firstPhoneCode === 1" :currentCode="phoneCodes[currentIndex]" @updatePhoneCode="onUpdatePhoneCode($event)"></apply-phone-code-box>

      <send-updates-box v-if="showFinalBox" :numberOfGuests="JourneyList.length.toString()" @sendUpdates="SendUpdates($event)"></send-updates-box>
    </div>

    <div class="MainPageMobile" v-if="isMobile == true">
      <div :style="{ width: windowWidth + 'px' }" style="position: fixed; z-index: 100; top: 0; height: 31px; display: flex; justify-content: space-between; align-items: center; padding: 16px; border-bottom: 2px solid #4aba96; background: #fafafa">
        <img width="20" height="20" @click="goBack()" src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/left.svg" />
        <div style="display: flex; flex-direction: column; align-items: center; justify-content: center">
          <h4>{{ hotelName }}</h4>
          <h5>Reservation update</h5>
        </div>
        <div style="width: 20px; height: 20px"></div>
      </div>

      <div style="display: flex; margin-top: 64px; justify-content: center; padding: 32px 24px; flex-direction: column; gap: 8px">
        <h3>Reservation list</h3>
        <p>Fill out the information for each reservation, and make your upcoming stay as smooth as possible.</p>
      </div>

      <FloatingBottomBar
        :headerText="isReservationDone() + ' out of ' + JourneyList.length + ' updated'"
        :bodyText="isReservationDone() !== JourneyList.length ? 'Update the remaining reservations' : 'Confirm & send updates'"
        :active="isReservationDone() == JourneyList.length"
        :isMobile="true"
        @updateConfirm="confirmPressed()"
        @updateConfirmNotReady="confirmNotReadyPressed()" />

      <div style="padding-bottom: 140px; overflow-y: scroll; display: flex; flex-direction: column">
        <div style="display: flex; flex-direction: column; align-items: center" v-for="(journey, index) of JourneyList" :key="index">
          <div class="reservation-box">
            <div class="reservation-header" v-if="mobileShowMore[index] == false" v-on:click="mobileShowMore[index] = true">
              <h1 style="margin-left: 16px">Reservation {{ index + 1 }}</h1>
              <div class="header-button-wrapper">
                <label-bar v-if="reservationDone(journey)" :text="'Filled out'" :type="'succes'"></label-bar>
                <img src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/down.svg" />
              </div>
            </div>

            <div class="reservation-header" v-if="mobileShowMore[index] == true" v-on:click="mobileShowMore[index] = false">
              <h1 style="margin-left: 16px">Reservation {{ index + 1 }}</h1>
              <div class="header-button-wrapper">
                <label-bar v-if="reservationDone(journey)" :text="'Filled out'" :type="'succes'"></label-bar>
                <img src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/up.svg" />
              </div>
            </div>

            <div class="reservation-info" v-if="mobileShowMore[index] == true">
              <div class="res-info">
                <div style="display: inline-flex; align-items: center; gap: 8px; margin-bottom: 4px" v-if="!GroupBookingList[0].HideGuestRoom">
                  <img width="18" height="18" src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/double_bed.svg" />
                  <p>{{ findRoomType(journey.RoomType) }}</p>
                </div>
                <div style="display: inline-flex; align-items: center; gap: 8px; margin-bottom: 4px">
                  <img width="18" height="18" src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/person.svg" />
                  <p>{{ journey.NumberOfGuest }}</p>
                </div>
                <div style="display: inline-flex; align-items: center; gap: 8px; margin-bottom: 4px">
                  <img width="18" height="18" src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/clock.svg" />
                  <p>{{ formatDate(journey.StartDate) }}</p>
                  <img width="14" height="14" src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/arrow_1_5px/right.png" />
                  <p>{{ formatDate(journey.EndDate) }}</p>
                </div>
              </div>
              <div style="height: 1px; width: 91%; background: #dfdfdf; margin-bottom: 24px"></div>

              <input-field-with-message
                :label="'First name'"
                :inputValue="journey.FirstName"
                :widthProcent="'91%'"
                :isRequired="GroupBookingList[0].FirstNameRequired"
                :emailDuplicate="false"
                :showError="redBorder(journey.FirstName, index)"
                @value="handleInputChange(journey, 'FirstName', $event)"></input-field-with-message>
              <div style="height: 16px"></div>
              <input-field-with-message
                :label="'Last name'"
                :inputValue="journey.LastName"
                :widthProcent="'91%'"
                :isRequired="GroupBookingList[0].LastNameRequired"
                :emailDuplicate="false"
                :showError="redBorder(journey.LastName, index)"
                @value="handleInputChange(journey, 'LastName', $event)"></input-field-with-message>
              <div style="height: 16px"></div>
              <input-field-with-message
                :label="'Email'"
                :inputValue="journey.Email"
                :widthProcent="'91%'"
                :isRequired="GroupBookingList[0].EmailRequired"
                :emailDuplicate="duplicateEmail(journey.Email, index)"
                :showError="redBorder(journey.Email, index, 'Email')"
                @value="handleInputChange(journey, 'Email', $event)"
                @error="handleError(journey, 'Email', $event)"></input-field-with-message>
              <div style="height: 16px"></div>

              <div style="display: inline-flex; gap: 8px; width: 90%; margin-bottom: 16px">
                <div v-on:click="showCurrentCodePicker(index)">
                  <country-code-picker
                    :showError="redBorder(phoneCodes[index], index)"
                    :currentCode="phoneCodes[index]"
                    :country="hotelCountry"
                    :showAbove="GroupBookingList.length <= index + 1 || (GroupBookingList.length - 1 <= index + 1 && GroupBookingList.length > 2)"
                    :isMobile="true"
                    @updateCode="onUpdateCode($event)" />
                </div>
                <input-field-with-message
                  :label="'Phone number'"
                  :inputValue="phonenumbers[index]"
                  :widthProcent="'100%'"
                  :isRequired="GroupBookingList[0].PhoneNumberRequired"
                  :emailDuplicate="false"
                  :showError="redBorder(phonenumbers[index], index, 'PhoneNumber')"
                  @value="handleInputChange(journey, 'PhoneNumber', $event)"
                  @error="handleError(journey, 'PhoneNumber', $event)"></input-field-with-message>
              </div>
            </div>
          </div>
          <div style="height: 16px" />
        </div>
        <div style="display: flex; flex-direction: column; align-items: center">
          <div class="reservation-box-saved" style="margin-bottom: 8px" v-for="(journey, index) of DeleteList" :key="index">
            <div class="reservation-header">
              <h1 style="margin-left: 16px">Reservation {{ index + JourneyList.length + 1 }}</h1>
              <div style="margin-right: 16px">
                <label-bar :type="'error'" :text="'Deleted'"></label-bar>
              </div>
            </div>

            <div class="reservation-info">
              <p class="saved-info">{{ journey.FirstName }} {{ journey.LastName }}</p>
              <p class="saved-info">{{ journey.Email }}</p>
              <p class="saved-info" style="margin-bottom: 24px">
                {{ phoneCodes[index + JourneyList.length] }} &nbsp;
                {{ phonenumbers[index + JourneyList.length] }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showCodePicker[currentIndex] == true">
        <phone-code-picker @updateCode="onUpdateCode($event)" :currentCode="phoneCodes[currentIndex]" />
      </div>
      <apply-phone-code-box v-if="firstPhoneCode === 1" :currentCode="phoneCodes[currentIndex]" @updatePhoneCode="onUpdatePhoneCode($event)"></apply-phone-code-box>
      <send-updates-box v-if="showFinalBox" :numberOfGuests="JourneyList.length.toString()" @sendUpdates="SendUpdates($event)"></send-updates-box>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import NewInfoBox from "@/components/NewInfoBox.vue"
import FloatingBottomBar from "@/components/FloatingBottomBar.vue"
import { JourneyV3 } from "../api/Hotel/JourneyV3/Models/Journey-List"
import { groupBookingInt } from "../api/Hotel/JourneyV3/Models/group-booking"
import JourneyV3Service from "../api/Hotel/JourneyV3/JourneyV3Service"
import HotelV2Service from "@/api/Hotel/Hotelv2/HotelV2Service"
import { pmsAndResNumbers } from "../api/Hotel/JourneyV3/Models/Get-Group-Booking-Request"
import { CountryCodes } from "@/api/User/V2Lookup/Models/country-codes"
import libphonenumber from "google-libphonenumber"
import PhoneCodePicker from "@/components/PhoneCodePicker.vue"
import DeleteConfirm from "@/components/DeleteConfirm.vue"
import Toaster from "@/components/Toaster.vue"
import LabelBar from "@/components/LabelBar.vue"
import localstorage from "@/store/localstorage"
import CountryCodePicker from "@/components/CountryCodePicker.vue"
import moment from "moment"
import InputFieldWithMessage from "@/components/InputFieldWithMessage.vue"
import { RoomTypes } from "@/api/Hotel/Hotelv2/RoomTypes"
import ApplyPhoneCodeBox from "@/components/ApplyPhoneCodeBox.vue"
import SendUpdatesBox from "@/components/SendUpdatesBox.vue"
import { UpdateGroupBookingAndJourney } from "@/api/Hotel/JourneyV3/Models/Update-Journeys-request"

@Options({
  components: {
    NewInfoBox,
    FloatingBottomBar,
    PhoneCodePicker,
    DeleteConfirm,
    Toaster,
    LabelBar,
    CountryCodePicker,
    InputFieldWithMessage,
    ApplyPhoneCodeBox,
    SendUpdatesBox,
  },
})
export default class GroupBookingView extends Vue {
  JourneyList: JourneyV3[] = []
  DeleteList: JourneyV3[] = []
  GroupBookingList: groupBookingInt[] = []
  bookingToBeUpdated: UpdateGroupBookingAndJourney = {
    Journeys: [],
    GroupId: "",
    Link: "",
    HotelEmails: "",
    HotelId: "",
    HotelName: "",
    GroupName: "",
    MainGuestName: "",
    MainGuestEmail: "",
    PmsName: "",
    MainGuestPhoneNumber: "",
  }
  pmsAndResNumbers: pmsAndResNumbers[] = []
  countryCodes: CountryCodes[] = []
  saved: boolean[] = []
  notSaved: boolean[] = []
  morePushedArray: boolean[] = []
  active = "z-index: 2;"
  phoneCodes: string[] = []
  phonenumbers: string[] = []
  actualPhonenumber: string[] = []
  showCodePicker: boolean[] = []
  mobileShowMore: boolean[] = []
  currentIndex = 0
  deleteIndex = -1
  showError = false
  showSucces = false
  isMobile = false
  hotelId = ""
  groupId = ""
  showAbove = false
  hotelName = ""
  hotelCountry = ""
  MainGuestName = ""
  MainGuestEmail = ""
  MainGuestPhoneNumber = ""
  confirmNotReady = false
  RoomTypes: RoomTypes[] = []
  emails: string[] = []
  firstPhoneCode = 0
  showFinalBox = false
  showWarning = false
  windowWidth = "400"
  errorInEmail: boolean[] = []
  errorInPhoneNumber: boolean[] = []
  async mounted() {
    this.groupId = this.$route.params.groupId as string
    this.hotelId = this.$route.params.hotelId as string
    localstorage.saveHotelId(this.hotelId)
    window.addEventListener("resize", this.onResize, { passive: true })
    this.onResize()
    this.addListeners()
    await this.getGroupBooking(this.hotelId, this.groupId)

    await this.getHotel(this.hotelId)
    await this.getRoomTypes(this.hotelId)
  }

  beforeDestroy() {
    this.removeListeners()
  }

  addListeners() {
    window.addEventListener("beforeunload", this.showLeaveWarning)
    window.addEventListener("beforeRouteLeave", this.showLeaveWarning)
    window.addEventListener("popstate", this.showLeaveWarning)
  }

  removeListeners() {
    window.removeEventListener("beforeunload", this.showLeaveWarning)
    window.removeEventListener("beforeRouteLeave", this.showLeaveWarning)
    window.removeEventListener("popstate", this.showLeaveWarning)
  }
  beforeRouteLeave() {
    localstorage.clear()
  }

  onResize() {
    const mediaQuery = window.matchMedia("(max-width: 520px)")
    this.windowWidth = (window.innerWidth - 32).toString()
    this.isMobile = mediaQuery.matches
  }

  async showLeaveWarning(event: any) {
    for (let index = 0; index < this.JourneyList.length; index++) {
      this.JourneyList[index].PhoneNumber = this.phoneCodes[index] + this.phonenumbers[index]
    }

    const journeyList = JSON.stringify(this.JourneyList)
    await localstorage.saveJourney(journeyList)
    const phoneCodeList = JSON.stringify(this.phoneCodes)
    await localstorage.SavePhoneCode(phoneCodeList)
    const phoneNbrString = JSON.stringify(this.phonenumbers)
    await localstorage.SavePhoneNbr(phoneNbrString)

    event.preventDefault()

    event.returnValue = "Are you sure you want to leave? Your unsaved changes may be lost."

    this.removeListeners()
    this.addListeners()
  }

  formatDate(date: any): string {
    if (date) return moment(date).format("ddd, MMM. DD, YYYY")
    else return ""
  }
  handleInputChange(journey: JourneyV3, valueToChange: string, event: any) {
    if (valueToChange === "FirstName") {
      journey.FirstName = event
    } else if (valueToChange === "LastName") {
      journey.LastName = event
    } else if (valueToChange === "Email") {
      journey.Email = event
      this.emails = []
      this.JourneyList.forEach((journey) => {
        this.emails.push(journey.Email)
      })
    } else if (valueToChange === "PhoneNumber") {
      this.phonenumbers[this.JourneyList.indexOf(journey)] = event
    }
  }
  handleError(journey: JourneyV3, valueToChange: string, event: any) {
    const index = this.JourneyList.indexOf(journey)
    if (valueToChange === "Email") {
      this.errorInEmail[index] = event
    } else if (valueToChange === "PhoneNumber") {
      this.errorInPhoneNumber[index] = event
    }
  }

  showCurrentCodePicker(index: number) {
    this.currentIndex = index
    this.showCodePicker[index] = true
  }
  anyShowCodePicker(): boolean {
    return this.showCodePicker.some((element) => element)
  }

  onUpdateCode(newData: string) {
    if (newData !== "+") {
      this.phoneCodes[this.currentIndex] = newData
      this.firstPhoneCode++
    }
    for (let index = 0; index < this.showCodePicker.length; index++) {
      this.showCodePicker[index] = false
    }
  }
  closeCodePicker() {
    for (let index = 0; index < this.showCodePicker.length; index++) {
      this.showCodePicker[index] = false
    }
  }
  onUpdateDelete(deleted: string) {
    if (deleted == "yes") {
      this.JourneyList[this.deleteIndex].PhoneNumber = this.phoneCodes[this.deleteIndex] + this.phonenumbers[this.deleteIndex]

      var phoneCode = this.phoneCodes.splice(this.deleteIndex, 1)
      this.phoneCodes.push(phoneCode[0])
      var phoneNumber = this.phonenumbers.splice(this.deleteIndex, 1)
      this.phonenumbers.push(phoneNumber[0])

      this.DeleteList.push(this.JourneyList[this.deleteIndex])
      this.JourneyList.splice(this.deleteIndex, 1)
      this.currentIndex = -1
      this.morePushedArray[this.deleteIndex] = false
      this.showSucces = true
      this.saved.splice(this.deleteIndex, 1)
      this.notSaved.splice(this.deleteIndex, 1)
    }
    this.deleteIndex = -1
  }
  onUpdatePhoneCode(updated: string) {
    if (updated === "yes") {
      var currentCode = this.phoneCodes[this.currentIndex]
      for (let index = 0; index < this.phoneCodes.length; index++) {
        this.phoneCodes[index] = currentCode
      }
    }
    this.firstPhoneCode = 10
  }

  getHotelCountry(): string {
    return this.hotelCountry ? this.hotelCountry : ""
  }

  async getHotel(hotelId: string) {
    try {
      var temp
      temp = await HotelV2Service.GetHotel(hotelId)
      this.hotelName = temp.Name
      this.hotelCountry = temp.Address.Country
    } catch (error) {
      console.error(error)
    }
  }
  async getRoomTypes(hotelId: string) {
    try {
      this.RoomTypes = await HotelV2Service.GetRoomTypes(hotelId)
    } catch (error) {
      console.error(error)
    }
  }

  findRoomType(type: string): string {
    var temp = type
    this.RoomTypes.forEach((element) => {
      if (element.TypeCode === type) {
        temp = element.Description
      }
    })
    return temp
  }

  async getGroupBooking(hotelId: string, groupId: string) {
    const journeyString = await localstorage.getJourney()
    const phoneCodeString = await localstorage.GetPhoneCode()
    const phoneNbrString = await localstorage.GetPhoneNbr()

    var localstorageSaved = false
    if (typeof journeyString === "string") localstorageSaved = true

    try {
      this.GroupBookingList = await JourneyV3Service.GetGroupBookingByGroupId(hotelId, groupId)

      if (moment(this.GroupBookingList[0].ExpiryDate) < moment()) {
        this.$router.push("/LinkExpired")
        return
      }

      this.MainGuestEmail = this.GroupBookingList[0].MainGuestEmail
      this.MainGuestPhoneNumber = this.GroupBookingList[0].MainGuestPhoneNumber
      this.MainGuestName = this.GroupBookingList[0].MainGuestName
    } catch (error) {
      console.error(error)
      return
    }

    this.GroupBookingList.forEach((booking) => {
      const { PMSBookingNumber, ReservationNumber } = booking
      this.pmsAndResNumbers.push({
        pmsBookingNumber: PMSBookingNumber,
        reservationNumber: ReservationNumber,
      })
    })

    try {
      if (!localstorageSaved) this.JourneyList = await JourneyV3Service.GetJourneysByListOfPms(hotelId, this.pmsAndResNumbers)
      else if (journeyString !== null) {
        this.JourneyList = JSON.parse(journeyString)
        if (typeof phoneCodeString == "string" && phoneCodeString !== "") this.phoneCodes = JSON.parse(phoneCodeString)
        if (typeof phoneNbrString == "string" && phoneNbrString !== "") this.phonenumbers = JSON.parse(phoneNbrString)
      }
    } catch (error) {
      console.error(error)
      return
    }
    if (!localstorageSaved)
      this.JourneyList.forEach((journey, index) => {
        const { Email, PhoneNumber } = journey
        const code = PhoneNumber !== null ? this.getCode(journey) : "0"
        const phoneNumber = PhoneNumber !== null ? PhoneNumber.replace(code, "") : ""
        const actualPhoneNumber = PhoneNumber !== null ? PhoneNumber : ""

        if (!this.phonenumbers.includes(phoneNumber)) {
          this.phoneCodes.push(code)
          this.phonenumbers.push(phoneNumber)
          this.actualPhonenumber.push(actualPhoneNumber)
        } else if (this.phonenumbers.includes(phoneNumber)) {
          this.phoneCodes.push("")
          this.phonenumbers.push("")
          this.actualPhonenumber.push("")
          journey.PhoneNumber = ""
        }

        if (!this.emails.includes(Email)) {
          this.emails.push(Email)
        } else if (this.emails.includes(Email)) {
          journey.Email = ""
          this.emails.push("")
        }

        this.morePushedArray[index] = false
        this.showCodePicker[index] = false
        this.mobileShowMore[index] = false
        this.errorInEmail[index] = false
        this.errorInPhoneNumber[index] = false
      })
    else {
      this.JourneyList.forEach((journey, index) => {
        const actualPhoneNumber = journey.PhoneNumber !== null ? journey.PhoneNumber : ""

        this.actualPhonenumber.push(actualPhoneNumber)
        this.emails.push(journey.Email)

        this.morePushedArray[index] = false
        this.showCodePicker[index] = false
        this.mobileShowMore[index] = false
        this.errorInEmail[index] = false
        this.errorInPhoneNumber[index] = false
      })
    }

    this.mobileShowMore[0] = true
  }

  async deleteCurrent(index: number) {
    this.deleteIndex = index
  }

  morePushed(index: number) {
    this.morePushedArray[index] == false ? (this.morePushedArray[index] = true) : (this.morePushedArray[index] = false)
  }

  getCode(Journey: JourneyV3): string {
    if ((Journey.PhoneNumber !== null || Journey.PhoneNumber !== "") && !/^\d+$/.test(Journey.PhoneNumber) && Journey.PhoneNumber.length > 4) {
      const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()
      var phoneNumber = Journey.PhoneNumber
      const phone = phoneUtil.parseAndKeepRawInput(phoneNumber)
      var countryCode = "+" + phone.getCountryCode()
      return countryCode
    } else return ""
  }

  redBorder(string: any, index: number, type?: string): boolean {
    if (this.isReservationDone() !== this.JourneyList.length + this.DeleteList.length && this.confirmNotReady && type === null) {
      if (string == "" || string == null) return true
    } else if (string === "") return false

    if (this.emails.filter((e, i) => e === string && i !== index).length > 0) return true

    if (this.phonenumbers.filter((e, i) => e === string && i !== index).length > 0) return true

    if (this.confirmNotReady && string.length > 0) {
      if ((type === "Email" && this.errorInEmail[index]) || (type === "PhoneNumber" && this.errorInPhoneNumber[index])) return true
    }
    return false
  }

  duplicateEmail(email: string, index: number): boolean {
    if (email === "") return false
    if (this.emails.filter((e, i) => e === email && i !== index).length > 0) return true
    else return false
  }

  duplicatePhone(phonenumber: string, index: number): boolean {
    if (phonenumber === "") return false
    if (this.phonenumbers.filter((e, i) => e === phonenumber && i !== index).length > 0) return true
    else return false
  }

  codePickerShowAbove(index: number) {
    if (this.GroupBookingList.length > 4 && (index + 1 === this.GroupBookingList.length || index + 1 === this.GroupBookingList.length - 1)) return true
    else return false
  }

  isReservationDone(): number {
    let save = 0
    const groupBooking = this.GroupBookingList[0]

    this.JourneyList.forEach((element, index) => {
      if ((groupBooking.FirstNameRequired && !element.FirstName) || (groupBooking.LastNameRequired && !element.LastName) || (groupBooking.EmailRequired && !element.Email) || (groupBooking.PhoneNumberRequired && (!this.phoneCodes[index] || !this.phonenumbers[index]))) {
        return
      }
      if (this.errorInEmail[index] || this.errorInPhoneNumber[index]) {
        return
      }
      save++
    })

    return save
  }
  reservationDone(element: JourneyV3): boolean {
    var index = this.JourneyList.indexOf(element)
    const groupBooking = this.GroupBookingList[0]
    if (this.errorInEmail[index] || this.errorInPhoneNumber[index]) return false
    else if ((groupBooking.FirstNameRequired && !element.FirstName) || (groupBooking.LastNameRequired && !element.LastName) || (groupBooking.EmailRequired && !element.Email) || (groupBooking.PhoneNumberRequired && (!this.phoneCodes[index] || !this.phonenumbers[index]))) {
      return false
    } else {
      return true
    }
  }

  confirmNotReadyPressed() {
    this.confirmNotReady = true
  }

  async confirmPressed() {
    this.showFinalBox = true
  }
  async SendUpdates(string: string) {
    this.showFinalBox = false

    if (string === "yes") {
      for (let index = 0; index < this.JourneyList.length; index++) {
        this.JourneyList[index].PhoneNumber = this.phoneCodes[index] + this.phonenumbers[index]
      }
      this.bookingToBeUpdated.GroupId = this.JourneyList[0].GroupId
      this.bookingToBeUpdated.Link = ""
      this.bookingToBeUpdated.Journeys = this.JourneyList
      this.bookingToBeUpdated.GroupName = this.GroupBookingList[0].GroupName
      this.bookingToBeUpdated.MainGuestEmail = this.GroupBookingList[0].MainGuestEmail
      this.bookingToBeUpdated.MainGuestPhoneNumber = this.GroupBookingList[0].MainGuestPhoneNumber
      this.bookingToBeUpdated.MainGuestName = this.GroupBookingList[0].GuestName
      this.bookingToBeUpdated.HotelId = this.GroupBookingList[0].HotelId
      await JourneyV3Service.PutJourneyDataFromGroupBooking(this.bookingToBeUpdated)
      this.removeListeners()
      this.$router.push("/GroupBookingFinalized")
    }
  }
  async goBack() {
    const confirmLeave = window.confirm("Are you sure you want to leave? Your data have not been sent to the hotel yet.")
    if (confirmLeave) {
      const journeyList = JSON.stringify(this.JourneyList)
      await localstorage.saveJourney(journeyList)
      const phoneCodeList = JSON.stringify(this.phoneCodes)
      await localstorage.SavePhoneCode(phoneCodeList)
      const phoneNbrString = JSON.stringify(this.phonenumbers)
      await localstorage.SavePhoneNbr(phoneNbrString)
      this.$router.push(`/groupbooking/${this.groupId}/${this.hotelId}`)
    }
  }
}
</script>

<style scoped lang="scss">
body {
  background-color: transparent !important;
}
.MainPageMobile {
  width: 100%;
  background: #fafafa;
  position: absolute;
  top: 0;
  left: 0;

  h3 {
    text-align: left;
    color: $ag-black;
    font-family: $font;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
  }
  h4 {
    color: $ag-black;
    font-family: $font;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
  }
  h5 {
    color: $ag-black;
    font-family: $font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
  }
  h1 {
    text-align: left;
    color: $ag-black;
    font-family: $font;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  h2 {
    text-align: left;
    color: $ag-black;
    font-family: $font;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 4px;
    margin-top: 0;
  }
  p {
    color: $ag-black;
    font-family: $font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
  }
  .button {
    margin-top: 8px;
    margin-bottom: 24px;
    height: 48px;
    width: 90%;
    border-radius: 16px;
    padding: 13px 16px 16px 16px;
    background: $ag-black;
    color: $ag-white;
    font-family: $font;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    cursor: pointer;
    border: none;
  }
  .button-not-active {
    margin-top: 8px;
    margin-bottom: 24px;
    height: 48px;
    width: 90%;
    border-radius: 16px;
    padding: 13px 16px 16px 16px;
    background: $ag-light-grey;
    color: $ag-white;
    font-family: $font;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    border: none;
  }
  button:hover {
    opacity: 0.9;
  }
  .more-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background: $ag-cream;
    padding: 5px;
    border-image-width: 24px;
    border: none;
  }

  .info-objects {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 16px;
    p {
      color: $ag-black;
      font-family: $font;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin: 0;
    }
    b {
      color: $ag-black;
      font-family: $font;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin: 0;
    }
    h4 {
      color: $ag-black;
      font-family: $font;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin: 0;
      text-align: left;
    }
    .purple-box {
      width: 100%;
      height: 340px;
      border-radius: 16px;
      background: $ag-light-purple;
      box-shadow: 1px 2px 10px 0px rgba(34, 34, 34, 0.05);
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      overflow: hidden;
      .column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        padding: 24px;
        width: 328px;
      }
      img {
        position: relative;
        top: -79px;
        right: -135px;
        width: 229px;
      }
    }
    .white-box {
      padding-bottom: 24px;
      width: 100%;
      border-radius: 16px;
      background: $ag-white;
      box-shadow: 1px 2px 10px 0px rgba(34, 34, 34, 0.05);
      display: flex;
      flex-direction: column;
      gap: 16px;
      .row {
        padding: 0 24px;
        display: inline-flex;
        justify-content: space-between;
      }
    }
  }
  .image-filter-white {
    filter: invert(96%) sepia(0%) saturate(7499%) hue-rotate(131deg) brightness(102%) contrast(105%);
  }

  .reservation-box {
    width: 90%;
    border-radius: 16px;
    background: $ag-white;
    box-shadow: 1px 2px 10px 0px rgba(34, 34, 34, 0.05);
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow-x: hidden;
    .reservation-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 72px;
      img {
        margin-right: 24px;
        width: 20px;
        height: 20px;
      }
      .header-button-wrapper {
        display: inline-flex;
        gap: 16px;
        align-items: center;
      }
    }
    .reservation-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      .res-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 91%;
        padding: 0 16px 24px 16px;
        p {
          font-family: $font;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          color: $ag-black;
          margin: 0;
        }
      }
      .input-bar-mobile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 16px 12px 16px;
        width: 83%;
        background: $ag-cream;
        border-radius: 12px;
        color: $ag-black;
        font-family: $font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 16px;
        outline: none;
      }
      .input-bar-name {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        margin-left: 8.5%;
      }
      .input-bar-phoneNumber {
        width: 90%;
        display: flex;
        gap: 8px;
        align-items: center;
        .dropDown-mobile {
          width: 24px;
          height: 24px;
          border: none;
        }
      }
    }
  }
}
.MainPagePc {
  width: 100%;
  min-height: 1000px;
  position: absolute;
  top: 0;
  left: 0;
  h1 {
    color: $ag-black;
    font-family: $font;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  h2 {
    color: $ag-black;
    font-family: $font;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  h3 {
    color: $ag-black;
    font-family: $font;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
  }
  h4 {
    color: $ag-black;
    font-family: $font;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
  }
  h5 {
    color: $ag-black;
    font-family: $font;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    margin: 0;
    width: fit-content;
  }
  .regular {
    color: $ag-medium-grey;
    font-family: $font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .table-body {
    color: $ag-black;
    font-family: $font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    position: relative;
  }
  .info-objects {
    width: 1280px;
    height: 240px;
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 50px;
    p {
      color: $ag-black;
      font-family: $font;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin: 0;
    }
    b {
      color: $ag-black;
      font-family: $font;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin: 0;
    }
    h4 {
      color: $ag-black;
      font-family: $font;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin: 0;
      text-align: left;
    }
    .purple-box {
      width: 100%;
      border-radius: 16px;
      background: $ag-light-purple;
      box-shadow: 1px 2px 10px 0px rgba(34, 34, 34, 0.05);
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      overflow: hidden;
      .column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        padding: 24px;
        width: 328px;
      }
      img {
        position: relative;
        top: -320px;
        right: -348px;
        width: 375px;
      }
    }
    .white-box {
      width: 100%;
      border-radius: 16px;
      background: $ag-white;
      box-shadow: 1px 2px 10px 0px rgba(34, 34, 34, 0.05);
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 24px;
      .row {
        display: inline-flex;
        justify-content: space-between;
      }
    }
  }
  .input-bar {
    display: flex;
    outline: none;
    background: $ag-cream;
    color: $ag-black;
    font-family: $font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    border-radius: 12px;
    padding: 12px 18px 12px 18px;
    width: -webkit-fill-available;
    height: 30px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    .dropDown {
      display: flex;
      align-items: center;
      border: none;
      outline: none;
      background: transparent;
      border-radius: 12px;
      cursor: pointer;
      padding: 0;
    }
  }
  .input-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    p {
      color: $ag-black;
      font-family: $font;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin: 0;
    }
    .name-and-input {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      width: 100%;
    }
    .phone-input {
      display: inline-flex;
      gap: 8px;
      width: 100%;
    }
  }
  .button {
    height: 48px;
    width: 120px;
    border-radius: 16px;
    padding: 13px 16px 16px 16px;
    background: $ag-black;
    color: $ag-white;
    font-family: $font;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    border: none;
    cursor: pointer;
  }
  .button-not-active {
    height: 48px;
    width: 120px;
    border-radius: 16px;
    padding: 13px 16px 16px 16px;
    background: $ag-light-grey;
    color: $ag-white;
    font-family: $font;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    border: none;
  }
  button:hover {
    opacity: 0.9;
  }
  .image-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background: $ag-cream;
    padding: 5px;
    border-image-width: 24px;
    border: none;
    cursor: pointer;
  }
  .image-button-pressed {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background: $ag-white;
    padding: 5px;
    border-image-width: 24px;
    border: 1px solid $ag-light-grey;
    cursor: pointer;
  }
  .image-filter-white {
    filter: invert(96%) sepia(0%) saturate(7499%) hue-rotate(131deg) brightness(102%) contrast(105%);
  }
  .floating-box {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: fit-content;
    background: $ag-white;
    border: 1px solid $ag-light-grey;
    filter: drop-shadow(0px 2px 10px rgba(34, 34, 34, 0.1));
    position: absolute;
    border-radius: 12px;
    z-index: 5;
    padding: 12px;
    gap: 8px;
    right: 24px;
    top: 65px;
    .floating-box-row {
      display: inline-flex;
      align-items: center;
      padding: 12px 16px 12px 16px;
      gap: 16px;
      border: none;
      background: $ag-white;
      border-radius: 12px;
      cursor: pointer;
      p {
        margin: 0;
        color: $ag-red;
        font-family: $font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
    .floating-box-row:hover {
      background: $ag-cream;
    }
  }

  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .tableborder {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .booking-table {
      max-width: 1280px;
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0px 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 1;
      position: relative;
      margin-bottom: 100px;
      .table-header {
        width: 100%;
        display: inline-flex;
        justify-content: flex-start;
        gap: 8px;
      }
      .table-row {
        width: 1280px;
        height: 200px;
        display: flex;
        flex-direction: column;
        background: $ag-white;
        align-items: center;
        border-radius: 12px;
        filter: drop-shadow(1px 2px 10px rgba(34, 34, 34, 0.05));
        border: 1px solid transparent;
        z-index: 1;
        position: relative;
        .phone-field {
          display: inline-flex;
        }
        .check {
          width: 0;
          height: 0;
          position: absolute;
        }
      }
      .table-row-done {
        width: 1280px;
        height: 216px;
        display: inline-flex;
        gap: 8px;
        background: $ag-white;
        align-items: center;
        border-radius: 12px;
        filter: drop-shadow(1px 2px 10px rgba(34, 34, 34, 0.05));
        border: 1px solid $ag-black;
        z-index: 1;
        position: relative;
        .phone-field {
          display: inline-flex;
        }
        .check {
          position: absolute;
          z-index: 4;
          top: -10px;
          right: -10px;
          border-radius: 16px;
          background: $ag-white;
        }
      }

      .info-row {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        width: 96.25%;
        height: 24px;
        padding: 24px;

        p {
          margin: 0;
          color: $ag-black;
          font-family: $font;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
      .input-row {
        display: inline-flex;
        justify-content: flex-start;
        width: 96.25%;
        height: 80px;
        padding: 24px;
        gap: 16px;
      }
      th {
        color: $ag-black;
        font-family: $font;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        padding: 8px;
      }
      td {
        color: $ag-black;
        font-family: $font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        padding: 8px;
        z-index: 1;
        position: relative;
      }
    }
  }
}
</style>
