import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["required"]
const _hoisted_2 = {
  key: 1,
  class: "input-box normal"
}
const _hoisted_3 = ["required"]
const _hoisted_4 = {
  key: 0,
  class: "error-img",
  src: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.5/cross.svg"
}
const _hoisted_5 = {
  key: 1,
  class: "normal-img",
  src: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.5/i_letter.svg"
}
const _hoisted_6 = {
  key: 2,
  class: "blue-img",
  src: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.5/i_letter.svg"
}
const _hoisted_7 = {
  key: 4,
  class: "blue-text"
}
const _hoisted_8 = {
  key: 2,
  style: {"height":"4px"}
}
const _hoisted_9 = { class: "input-box-disabled" }
const _hoisted_10 = { class: "input-bar-disabled" }
const _hoisted_11 = {
  key: 0,
  class: "normal-img",
  src: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.5/exclamation_mark.svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.disabled)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.label === 'Phone number' ? 'phoneBox border-input-box' : 'border-input-box'),
          style: _normalizeStyle(_ctx.transformWidth())
        }, [
          (!_ctx.emailDuplicate && !_ctx.phoneDuplicate)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["input-box", _ctx.validateValue() || _ctx.showError ? 'error' : 'normal'])
              }, [
                _createElementVNode("p", {
                  class: _normalizeClass(!_ctx.bigLabel ? 'label-small' : 'label-big'),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$refs.myInput.focus()))
                }, _toDisplayString(_ctx.label), 3),
                _withDirectives(_createElementVNode("input", {
                  ref: "myInput",
                  class: "input-bar",
                  type: "text",
                  autocomplete: "off",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.stringValue) = $event)),
                  required: _ctx.isRequired,
                  onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onChange())),
                  onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.checkStringValid()))
                }, null, 40, _hoisted_1), [
                  [_vModelText, _ctx.stringValue]
                ])
              ], 2))
            : _createCommentVNode("", true),
          (_ctx.emailDuplicate || _ctx.phoneDuplicate)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("p", {
                  class: _normalizeClass(!_ctx.bigLabel ? 'label-small' : 'label-big'),
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$refs.myInput.focus()))
                }, _toDisplayString(_ctx.label), 3),
                _withDirectives(_createElementVNode("input", {
                  ref: "myInput",
                  class: "input-bar",
                  type: "text",
                  autocomplete: "off",
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.stringValue) = $event)),
                  required: _ctx.isRequired,
                  onInput: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onChange())),
                  onChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.checkStringValid()))
                }, null, 40, _hoisted_3), [
                  [_vModelText, _ctx.stringValue]
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.label === 'Phone number' ? 'move-left message' : _ctx.showMessage() === '' ? '' : 'message')
          }, [
            (_ctx.validateValue() || (_ctx.showError && _ctx.isRequired && !_ctx.emailDuplicate && !_ctx.phoneDuplicate))
              ? (_openBlock(), _createElementBlock("img", _hoisted_4))
              : _createCommentVNode("", true),
            (_ctx.isRequired && !_ctx.validateValue() && !_ctx.showError)
              ? (_openBlock(), _createElementBlock("img", _hoisted_5))
              : _createCommentVNode("", true),
            (!_ctx.validateValue() && (_ctx.emailDuplicate || _ctx.phoneDuplicate))
              ? (_openBlock(), _createElementBlock("img", _hoisted_6))
              : _createCommentVNode("", true),
            ((!_ctx.emailDuplicate && !_ctx.phoneDuplicate) || _ctx.validateValue())
              ? (_openBlock(), _createElementBlock("p", {
                  key: 3,
                  class: _normalizeClass(_ctx.showError || _ctx.validateValue() ? 'error-text' : 'normal-text')
                }, _toDisplayString(_ctx.showMessage()), 3))
              : _createCommentVNode("", true),
            (!_ctx.validateValue() && (_ctx.emailDuplicate || _ctx.phoneDuplicate))
              ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.showMessage()), 1))
              : _createCommentVNode("", true)
          ], 2),
          (!_ctx.validateValue() && (_ctx.emailDuplicate || _ctx.phoneDuplicate))
            ? (_openBlock(), _createElementBlock("div", _hoisted_8))
            : _createCommentVNode("", true)
        ], 6))
      : _createCommentVNode("", true),
    (_ctx.disabled)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "border-input-box",
          style: _normalizeStyle(_ctx.transformWidth())
        }, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("p", {
              class: _normalizeClass(!_ctx.bigLabel ? 'label-small' : 'label-big')
            }, _toDisplayString(_ctx.label), 3),
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.stringValue), 1)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.label === 'Phone number' ? 'move-left message' : _ctx.showMessage() === '' ? '' : 'message')
          }, [
            (_ctx.isRequired && !_ctx.validateValue() && !_ctx.showError)
              ? (_openBlock(), _createElementBlock("img", _hoisted_11))
              : _createCommentVNode("", true),
            _createElementVNode("p", {
              class: _normalizeClass(['normal-text', "disabled"])
            }, _toDisplayString(_ctx.showMessage()), 1)
          ], 2)
        ], 4))
      : _createCommentVNode("", true)
  ], 64))
}