import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-82a53ae4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"display":"flex","justify-content":"center"} }
const _hoisted_2 = {
  key: 0,
  class: "info-box"
}
const _hoisted_3 = { class: "columns" }
const _hoisted_4 = { class: "SemiBold" }
const _hoisted_5 = { class: "Regular" }
const _hoisted_6 = { class: "button-container" }
const _hoisted_7 = { class: "image-container" }
const _hoisted_8 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.show)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.headerText), 1),
              _createElementVNode("b", _hoisted_5, _toDisplayString(_ctx.bodyText), 1),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("button", {
                  class: "button",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.infoBoxClicked()))
                }, "Understood")
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("img", {
                class: "image",
                src: _ctx.imageSource
              }, null, 8, _hoisted_8)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}