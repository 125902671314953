<template>
  <div class="whole-delete-page">
    <div class="delete-box">
      <b>Delete reservation</b>
      <p>Are you sure you want to delete the reservation?</p>
      <div class="button-wrapper">
        <button class="button" v-on:click="returnToParent()">Cancel</button>
        <button class="delete-button" v-on:click="clickDelete()">Delete</button>
      </div>
    </div>
    <div class="overLay" v-on:click="returnToParent()" />
  </div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component"
import JourneyV3Service from "../api/Hotel/JourneyV3/JourneyV3Service"
import { pmsAndResNumbers } from "@/api/Hotel/JourneyV3/Models/Get-Group-Booking-Request"
import localstorage from "@/store/localstorage"

@Options({
  props: {},
})
export default class DeleteConfirm extends Vue {
  pmsAndResNumbers: pmsAndResNumbers[] = []

  clickDelete() {
    this.deleteCurrent()
  }

  async deleteCurrent() {
    this.$emit("updateDelete", "yes")
  }

  returnToParent() {
    this.$emit("updateDelete", "no")
  }
}
</script>

<style lang="scss">
@media screen and (max-width: 520px) {
  .whole-delete-page {
    top: 0;
    left: 0;
  }
  .delete-box {
    height: auto;
    width: 83%;

    .delete-button {
      width: 60%;
    }
  }
}
@media screen and (min-width: 522px) {
  .whole-delete-page {
    top: 0;
    left: auto;
  }
  .delete-box {
    height: 144px;
    width: 532px;

    .delete-button {
      width: 120px;
    }
  }
}

.whole-delete-page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 5;
}
b {
  text-align: left;
  color: $ag-black;
  font-family: $font;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
p {
  text-align: left;
  color: $ag-black;
  font-family: $font;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.delete-box {
  background: $ag-white;
  border-radius: 12px;
  position: relative;
  z-index: 5;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .button-wrapper {
    display: inline-flex;
    justify-content: center;
    gap: 16px;
    margin-top: 24px;
    .delete-button {
      height: 48px;
      border-radius: 16px;
      padding: 13px 16px 16px 16px;
      background: $ag-black;
      color: $ag-white;
      font-family: $font;
      font-size: 14px;
      font-weight: 400;
      line-height: 140%;
      border: none;
      cursor: pointer;
      filter: drop-shadow(1px 2px 10px rgba(34, 34, 34, 0.05));
    }
    .button {
      height: 48px;
      width: 120px;
      border-radius: 16px;
      padding: 13px 16px 16px 16px;
      background: $ag-cream;
      color: $ag-black;
      font-family: $font;
      font-size: 14px;
      font-weight: 400;
      line-height: 140%;
      border: none;
      cursor: pointer;
      filter: drop-shadow(1px 2px 10px rgba(34, 34, 34, 0.05));
    }
    .delete-button:hover {
      opacity: 0.9;
    }
    .button:hover {
      background: $ag-light-grey;
    }
  }
}

.overLay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 4;
}
</style>
