<template>
  <div style="display: flex; justify-content: center">
    <div class="info-box" v-if="show">
      <div class="columns">
        <div>
          <h2 class="SemiBold">{{ headerText }}</h2>
          <b class="Regular">{{ bodyText }}</b>
          <div class="button-container">
            <button class="button" @click="infoBoxClicked()">Understood</button>
          </div>
        </div>
        <div class="image-container">
          <img class="image" :src="imageSource" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component"

@Options({
  props: {
    headerText: String,
    bodyText: String,
    buttonText: String,
    imageSource: String,
  },
})
export default class NewInfoBox extends Vue {
  headerText!: string
  bodyText!: string
  buttonText!: string
  imageSource!: string
  show = true

  infoBoxClicked() {
    this.show = false
  }
}
</script>

<style scoped lang="scss">
@media screen and (max-width: 520px) {
  .info-box {
    width: 90%;
    margin-top: 0;
  }
  .image-container {
    top: 250px;
    right: 10%;
    position: absolute;
    width: 150px;
    height: 100px;
    .image {
      width: 150%;
      height: 100px;
    }
  }
}
@media screen and (min-width: 521px) {
  .info-box {
    width: 100%;
    margin-top: 24px;
  }
  .image-container {
    position: relative;
    right: -180px;
    top: 19px;
  }
}
.info-box {
  max-width: 1280px;
  background-color: $ag-light-purple;
  border-radius: 12px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow: hidden;
}

.button {
  height: 56px;
  width: fit-content;
  border-radius: 16px;
  padding: 18px 16px 18px 16px;
  background: $ag-black;
  color: $ag-white;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  cursor: pointer;
  border: none;
}
button:hover {
  opacity: 0.9;
}
.columns {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.SemiBold {
  text-align: left;
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 32px;
  color: $ag-black;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.Regular {
  display: flex;
  text-align: left;
  max-width: 808px;
  //width: 100%;
  color: $ag-black;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 16px;
  margin-left: 32px;
  margin-right: 32px;
}

.image {
  width: 400px;
  height: 240px;
  flex-shrink: 0;
  object-fit: cover;
}

.button-container {
  display: flex;
  margin-left: 32px;
  margin-bottom: 32px;
  margin-top: 24px;
  position: inherit;
  align-self: left;
}

.image-container {
  display: flex;
  width: 38%;
  height: 240px;
  padding-left: 32px;
  align-items: center;
  overflow: hidden;
}
</style>
