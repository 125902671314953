<template>
  <div>
    <div class="main-page-pc" v-if="isMobile == false">
      <h3 style="margin-top: 29px">Reservation update</h3>
      <h4>{{ hotelName }}</h4>
      <div style="width: 100%; height: 1px; background: rgba(34, 34, 34, 0.1); margin-top: 16px; margin-bottom: 40px" />

      <div style="display: flex; justify-content: center; width: 100%">
        <div style="display: flex; justify-content: center; width: 750px">
          <div class="left-info">
            <div class="title">The link has expired</div>
            <p style="width: 300px">
              You can contact {{ hotelName }} on
              <u>{{ hotelPhoneNumber }}</u>
              or
              <u>{{ hotelEmail }}</u>
            </p>
          </div>
          <div class="right-info">
            <img src="https://aeroguestblobstorageprod.blob.core.windows.net/illustrations/all Illustrations/316.Expired.svg" />
          </div>
        </div>
        <div style="position: absolute; bottom: 20px">
          <img src="https://aeroguestblobstorageprod.blob.core.windows.net/icons/AeroGuest_Black.svg" />
        </div>
      </div>
    </div>
  </div>
  <div class="main-page-mobile" v-if="isMobile == true">
    <div :style="{ width: windowWidth + 'px' }" style="position: fixed; z-index: 100; top: 0; height: 31px; display: flex; justify-content: center; align-items: center; padding: 16px; border-bottom: 2px solid #4aba96; background: #fafafa">
      <div style="display: flex; flex-direction: column; align-items: center; justify-content: center">
        <h4>{{ hotelName }}</h4>
        <h5>Reservation update</h5>
      </div>
    </div>

    <div :style="{ width: windowWidth + 'px' }" style="margin-top: 64px; display: flex; justify-content: center; padding: 96px 16px">
      <div>
        <img width="200" height="200" src="https://aeroguestblobstorageprod.blob.core.windows.net/illustrations/all Illustrations/316.Expired.svg" />
        <div class="title" style="margin-top: 40px">The link has expired</div>
        <p style="margin-top: 8px">
          You can contact {{ hotelName }} on
          <u>{{ hotelPhoneNumber }}</u>
          or
          <u>{{ hotelEmail }}</u>
        </p>
      </div>
      <div style="position: absolute; bottom: 20px">
        <img src="https://aeroguestblobstorageprod.blob.core.windows.net/icons/AeroGuest_Black.svg" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import HotelV2Service from "@/api/Hotel/Hotelv2/HotelV2Service"
import LabelBar from "@/components/LabelBar.vue"
import { Options, Vue } from "vue-class-component"
import localstorage from "@/store/localstorage"

@Options({
  components: {
    LabelBar,
  },
})
export default class LinkExpiredView extends Vue {
  isMobile = false
  hotelName = ""
  hotelPhoneNumber = "Phone"
  hotelEmail = "Email"
  windowWidth = "200"

  async mounted() {
    this.onResize()
    window.addEventListener("resize", this.onResize, { passive: true })
    const hotelId = localstorage.getHotelId()
    if (typeof hotelId === "string") var hotel = await HotelV2Service.GetHotel(hotelId)
    this.hotelName = hotel?.Name !== undefined ? hotel.Name : "HotelName"
    this.hotelEmail = hotel?.Email !== undefined ? hotel.Email : "HotelEmail"
    this.hotelPhoneNumber = hotel?.Phonenumber !== undefined ? hotel.Phonenumber : "HotelPhonenumber"
  }

  onResize() {
    const mediaQuery = window.matchMedia("(max-width: 520px)")
    this.windowWidth = (window.innerWidth - 32).toString()
    this.isMobile = mediaQuery.matches
  }
}
</script>

<style lang="scss">
.main-page-pc {
  h1 {
    color: $ag-black;
    font-family: $font;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
  }
  h3 {
    color: $ag-black;
    font-family: $font;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
  }
  h4 {
    color: $ag-black;
    font-family: $font;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
  }
  p {
    text-align: left;
    color: $ag-black;
    font-family: $font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0 !important;
  }
  .title {
    color: $ag-black;
    font-family: $font;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    margin: 0;
  }
  .left-info {
    padding-top: 160px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    justify-content: center;
    gap: 16px;
  }
  .right-info {
    padding-top: 160px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    box-sizing: border-box;
    gap: 16px;
    box-sizing: border-box;
  }
}
.main-page-mobile {
  width: 100%;
  position: absolute;
  background: #fafafa;
  height: 100%;
  top: 0;
  left: 0;
  h1 {
    color: $ag-black;
    font-family: $font;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
  }
  p {
    color: $ag-black;
    font-family: $font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
  }
  .link-box {
    width: 92%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background: $ag-white;
    border-radius: 12px;
    box-shadow: 1px 2px 10px 0px rgba(34, 34, 34, 0.05);
    .header {
      display: inline-flex;
      justify-content: space-between;
    }
  }
}
</style>
