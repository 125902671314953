<template>
  <div class="wholePage">
    <div class="overLay" v-on:click="returnToParent(currentCode)" />
    <div class="listBox">
      <div class="header">
        <button class="x-button" v-on:click="returnToParent(currentCode)">
          <img src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/cross.svg" />
        </button>
        <b>Country code</b>
      </div>

      <div class="search-container">
        <div class="search-bar">
          <input style="padding: 0 !important" class="search-bar" type="text" v-model="search" placeholder="Search country codes" />
          <img width="20" height="20" src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/magnifier.svg" />
        </div>
      </div>

      <div class="countries">
        <div style="width: 100%" v-for="(country, index) in filteredCountryCodes()" :key="index">
          <button v-if="currentCode !== '+' + country.CallingCode" class="country-row" v-on:click="returnToParent(country.CallingCode)">
            <div class="nameColoum">
              <p class="name">{{ country.country }}</p>
              <p class="native">{{ country.nativeCountry }}</p>
            </div>
            <p class="name">+{{ country.CallingCode }}</p>
          </button>

          <button v-if="currentCode == '+' + country.CallingCode" class="country-row" v-on:click="returnToParent(country.CallingCode)">
            <div class="nameColoum">
              <p class="name-bold">{{ country.country }}</p>
              <p class="native-bold">{{ country.nativeCountry }}</p>
            </div>
            <p class="name-bold">+{{ country.CallingCode }}</p>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Countries } from "@/api/User/V2Lookup/Models/countries"
import { CountryCodes } from "@/api/User/V2Lookup/Models/country-codes"
import v2LookupService from "@/api/User/V2Lookup/v2Lookup-service"

import { Options, Vue } from "vue-class-component"

@Options({
  props: {
    currentCode: String,
    country: String,
  },
})
export default class PhoneCodePicker extends Vue {
  countryCodes: CountryCodes[] = []
  countries: Countries[] = []
  currentCode!: string
  country!: string
  search = ""
  searchCountryCodes: CountryCodes[] = []

  mounted(): void {
    this.getCountryCode()
  }

  filteredCountryCodes() {
    const searchText = this.search.toLowerCase()
    return this.countryCodes.filter((country) => country.nativeCountry.toLowerCase().includes(searchText) || country.country.toLowerCase().includes(searchText) || country.CallingCode.toLowerCase().includes(searchText))
  }

  async getCountryCode() {
    this.countries = await v2LookupService.GetCountryCodePrefix()
    this.countries.forEach((x) => {
      if (x.CallingCodes)
        this.countryCodes.push({
          CallingCode: x.CallingCodes[0],
          country: x.Name,
          nativeCountry: x.NativeName,
        })
      if (!this.currentCode && this.country === x.Name) this.currentCode = x.CallingCodes[0]
    })
  }

  returnToParent(callingCode: string) {
    var string = callingCode
    if (!callingCode.includes("+")) string = "+" + callingCode
    this.$emit("updateCode", string)
  }
}
</script>
<style lang="scss">
@media screen and (max-width: 520px) {
  .listBox {
    width: 100% !important;
    height: 91%;
    position: fixed;
    left: 0;
    right: 0;
    top: 10%;
  }
  .header {
    width: 98% !important;
  }
  .search-container {
    width: 90%;
  }
}
@media screen and (min-width: 525px) {
  .listBox {
    width: 375px;
    height: 725px;
  }
}

.wholePage {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 4;
  top: 0;
}
b {
  color: $ag-black;
  font-family: $font;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.listBox {
  z-index: 5;
  background: $ag-white;
  border-radius: 12px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  .countries {
    margin-top: 165px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 16px 0 16px;
    .country-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 80px;
      width: 100%;
      border: none;
      outline: none;
      background: $ag-white;
      border-top: 1px solid $ag-light-grey;
      cursor: pointer;
      .nameColoum {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .native {
          color: $ag-dark-grey;
          font-family: $font;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          margin: 0;
          text-align: left;
        }
        .name {
          color: $ag-black;
          font-family: $font;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          margin: 0;
          text-align: left;
        }
        .native-bold {
          color: $ag-dark-grey;
          font-family: $font;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          margin: 0;
          text-align: left;
        }
      }
      .name-bold {
        color: $ag-black;
        font-family: $font;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin: 0;
        text-align: left;
      }
    }
    .country-row:hover {
      background: $ag-cream;
    }
  }

  .header {
    display: flex;
    width: 375px;
    justify-content: center;
    padding: 24px 0px 24px 0px;
    border-radius: 12px;
    position: fixed;
    background: $ag-white;
    .x-button {
      width: fit-content;
      height: fit-content;
      position: absolute;
      left: 23px;
      top: 18px;
      border: none;
      background: transparent;
      border-radius: 12px;
      padding: 4px;
      cursor: pointer;
    }
    .x-button:hover {
      background: $ag-cream;
    }
  }
  .search-container {
    width: 92%;
    padding: 24px 16px 24px 16px;
    background: $ag-white;
    position: fixed;
    margin-top: 66px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $ag-light-grey;
    .search-bar {
      border: none;
      outline: none;
      background: $ag-cream;
      color: $ag-black;
      font-family: $font;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      border-radius: 12px;
      height: 21px !important;
      padding: 16px 16px 16px 16px;
      width: -webkit-fill-available;
    }
    img:hover {
      background: $ag-light-grey;
    }
  }
}
.listBox::-webkit-scrollbar {
  display: none;
}

.overLay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 4;
}
</style>
