import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { style: {"display":"flex","justify-content":"center","width":"100%"} }
const _hoisted_2 = {
  key: 0,
  class: "BottomBar"
}
const _hoisted_3 = { class: "TextContainer" }
const _hoisted_4 = { class: "buttonWrapper" }
const _hoisted_5 = {
  key: 0,
  class: "bottomBar-mobile"
}
const _hoisted_6 = {
  key: 0,
  width: "20",
  height: "20",
  style: {"align-self":"center","filter":"invert(100%) sepia(100%) saturate(0%) hue-rotate(296deg) brightness(108%) contrast(103%)"},
  src: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/arrow_1_5px/right.png"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isMobile == false)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("b", null, _toDisplayString(_ctx.headerText), 1),
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.bodyTextRed ? 'red' : '')
              }, _toDisplayString(_ctx.bodyText), 3)
            ]),
            _createElementVNode("div", _hoisted_4, [
              (_ctx.active)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "button",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.confirm()))
                  }, "Send updates"))
                : _createCommentVNode("", true),
              (!_ctx.active)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "button-not-active",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.confirmNotReady()))
                  }, "Send updates"))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.isMobile == true)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_ctx.active)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "div-button",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.confirm && _ctx.confirm(...args)))
              }, [
                _createElementVNode("b", null, [
                  _createTextVNode(_toDisplayString(_ctx.headerText) + " ", 1),
                  (_ctx.withArrow)
                    ? (_openBlock(), _createElementBlock("img", _hoisted_6))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("p", {
                  class: _normalizeClass(_ctx.bodyTextRed ? 'red' : '')
                }, _toDisplayString(_ctx.bodyText), 3)
              ]))
            : _createCommentVNode("", true),
          (!_ctx.active)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "div-button-not-active",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.confirmNotReady()))
              }, [
                _createElementVNode("b", null, _toDisplayString(_ctx.headerText), 1),
                _createElementVNode("p", {
                  class: _normalizeClass(_ctx.bodyTextRed ? 'red' : '')
                }, _toDisplayString(_ctx.bodyText), 3)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}