import localstorage from "@/store/localstorage"
import axios from "axios"

const ApiService = {
  async postAsync(proxyPath: any, uri: any, data: any, auths?: any) {
    const response = await axios.post(uri, data, { headers: this.getHeaders(), auth: auths, baseURL: proxyPath })
    return response.data
  },
  async getAsync(proxyPath: any, uri: any) {
    const response = await axios.get(uri, { headers: this.getHeaders(), baseURL: proxyPath })
    return response.data
  },
  async deleteAsync(proxyPath: any, uri: any, data: any) {
    const response = await axios.delete(uri, { headers: this.getHeaders(), baseURL: proxyPath, data })
    return response.data
  },
  async putAsync(proxyPath: any, uri: any, data: any, auths?: any) {
    const response = await axios.put(uri, data, { headers: this.getHeaders(), auth: auths, baseURL: proxyPath })
    return response.data
  },

  getHeaders() {
    return {
      AGID: localstorage.getToken(),
      OS: "Web",
      "Content-Type": "application/json",
      Accept: "application/json",
    }
  },
}

export default ApiService
