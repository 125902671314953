<template>
  <div class="whole-update-page">
    <div class="update-box">
      <div style="display: flex; flex-direction: column; gap: 8px">
        <b>Apply country code</b>
        <p>You've selected {{ currentCode }} as a country code. Would you like to apply it to all reservations?</p>
      </div>
      <div class="button-wrapper">
        <button class="button" v-on:click="returnToParent()">This one only</button>
        <button class="update-button" v-on:click="clickUpdate()">Apply to all</button>
      </div>
    </div>
    <div class="overLay" v-on:click="returnToParent()" />
  </div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component"

@Options({
  props: {
    currentCode: String,
  },
})
export default class ApplyPhoneCodeBox extends Vue {
  currentCode!: string

  clickUpdate() {
    this.updateCurrent()
  }

  async updateCurrent() {
    this.$emit("updatePhoneCode", "yes")
  }

  returnToParent() {
    this.$emit("updatePhoneCode", "no")
  }
}
</script>

<style lang="scss">
@media screen and (max-width: 520px) {
  .whole-update-page {
    top: 0;
    left: 0;
  }
  .update-box {
    height: auto;
    width: 83%;

    .update-button {
      width: 50%;
    }
    .button {
      width: 50%;
    }
  }
}
@media screen and (min-width: 522px) {
  .whole-update-page {
    top: 0;
    left: auto;
  }
  .update-box {
    height: 144px;
    width: 532px;

    .update-button {
      width: 120px;
    }
  }
}

.whole-update-page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 5;
}
b {
  text-align: left;
  color: $ag-black;
  font-family: $font;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
p {
  text-align: left;
  color: $ag-black;
  font-family: $font;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.update-box {
  background: $ag-white;
  border-radius: 12px;
  position: relative;
  z-index: 5;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .button-wrapper {
    display: inline-flex;
    justify-content: flex-end;
    gap: 16px;
    .update-button {
      height: 48px;
      width: 162px;
      border-radius: 16px;
      padding: 13px 16px 16px 16px;
      background: $ag-black;
      color: $ag-white;
      font-family: $font;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      border: none;
      cursor: pointer;
      filter: drop-shadow(1px 2px 10px rgba(34, 34, 34, 0.05));
    }
    .button {
      height: 48px;
      border-radius: 16px;
      padding: 13px 16px 16px 16px;
      background: $ag-cream;
      color: $ag-black;
      font-family: $font;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      border: none;
      cursor: pointer;
      filter: drop-shadow(1px 2px 10px rgba(34, 34, 34, 0.05));
    }
    .update-button:hover {
      opacity: 0.9;
    }
    .button:hover {
      background: $ag-light-grey;
    }
  }
}

.overLay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 4;
}
</style>
