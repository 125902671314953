import BaseApiService from "@/api/BaseApiService"
import proxy from "@/api/proxy"
import { RoomTypes } from "./RoomTypes"

const HotelV2Service = {
  GetHotel(hotelId: string): Promise<any> {
    return BaseApiService.getAsync(proxy.HotelV2, `Hotel?id=${hotelId}&fromArchive=true`)
  },
  GetRoomTypes(hotelId: string): Promise<RoomTypes[]> {
    return BaseApiService.getAsync(proxy.HotelV2, `RoomType/Hotel/${hotelId}`)
  },
}

export default HotelV2Service
