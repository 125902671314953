<template>
  <div>
    <div class="MainPagePc" v-if="isMobile == false">
      <h3 style="margin-top: 29px">Reservation update</h3>
      <h4>{{ hotelName }}</h4>
      <div style="width: 100%; height: 1px; background: rgba(34, 34, 34, 0.1); margin-top: 16px" />
      <div style="display: flex; justify-content: center; width: 100%">
        <div style="display: flex; justify-content: center; width: 1440px">
          <div class="left-info">
            <div class="title">Welcome,</div>
            <div class="title">
              {{ mainGuestName }}
            </div>
            <body style="width: 400px; margin: 25px 0 42px 0 !important">
              When you Continue you’ll find a list of all the reservations for your upcoming stay. Let us know who’s coming and make the stay smooth for everyone.
            </body>
            <div class="button" v-on:click="continueToNextPage()">
              Continue
              <img width="24" height="24" src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/arrow_1_5px/right.png" />
            </div>
          </div>
          <div class="right-info">
            <div class="image-row">
              <img :src="hotelImage" style="object-fit: cover; object-position: center" />
              <div class="message-box" ref="messageBox">
                <h3 style="margin-bottom: 8px">Greetings from the hotel</h3>
                <body style="width: 100%" v-html="messageToGroup"></body>
                <div class="bottom-message-box" v-if="isOverflowing">
                  <div class="read-all" @click="showFullGreeting = true">
                    <u>Read all</u>
                    <img src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/arrow_1_5px/right.png" />
                  </div>
                </div>
              </div>
            </div>

            <div class="information-box">
              <h3>Information</h3>
              <div class="sub-row" style="margin-top: 8px">
                <body>
                  Reservation owner
                </body>
                <body style="font-weight: 500">
                  {{ mainGuestName }}
                </body>
              </div>
              <div class="sub-row">
                <body>
                  Email
                </body>
                <body style="font-weight: 500">
                  {{ mainGuestEmail }}
                </body>
              </div>
              <div class="sub-row">
                <body>
                  Phone number
                </body>
                <body style="font-weight: 500">
                  {{ mainGuestPhone }}
                </body>
              </div>
              <div class="sub-row">
                <body>
                  Number of reservations
                </body>
                <body style="font-weight: 500">
                  {{ numberOfReservations }}
                </body>
              </div>
              <div class="sub-row">
                <body>
                  Complete before
                </body>
                <body style="font-weight: 500">
                  {{ expireDate }}
                </body>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="showFullGreeting"
        @click="showFullGreeting = false"
        style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; position: absolute; z-index: 100; top: 0; left: 0; background: linear-gradient(180deg, rgba(34, 34, 34, 0.35) 0%, rgba(34, 34, 34, 0.35) 100%); backdrop-filter: blur(2px)">
        <div class="greetings-full-box" @click.stop>
          <div class="greetings-header">
            <div style="width: 16px; height: 16px"></div>
            <h5>Greetings from the hotel</h5>
            <img src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/cross.svg" @click="showFullGreeting = false" />
          </div>
          <div class="guest-message">
            <body v-html="messageToGroup"></body>
          </div>
        </div>
      </div>
    </div>

    <div class="MainPageMobile" v-if="isMobile == true">
      <div :style="{ width: windowWidth + 'px' }" style="position: fixed; z-index: 100; top: 0; left: 0; height: 31px; display: flex; justify-content: center; align-items: center; padding: 16px; border-bottom: 2px solid #dfdfdf; background: #fafafa">
        <div style="display: flex; flex-direction: column; align-items: center; justify-content: center">
          <h4>{{ hotelName }}</h4>
          <h5>Reservation update</h5>
        </div>
      </div>

      <FloatingBottomBar :headerText="'Continue'" :withArrow="true" :bodyText="''" :active="true" :isMobile="true" @updateConfirm="continueToNextPage()" />

      <img :src="hotelImage" style="width: 100%; height: 164px; object-fit: cover; object-position: center" />

      <div :style="{ width: parseInt(windowWidth) - 12 + 'px' }" style="display: flex; justify-content: center; flex-direction: column; align-items: center; gap: 16px; padding: 16px">
        <div class="top-information">
          <div class="title">Welcome,</div>
          <div class="title">
            {{ mainGuestName }}
          </div>
          <body style="margin-top: 8px">
            When you
            <b>Continue</b>
            you’ll find a list of all the reservations for your upcoming stay. Let us know who’s coming and make the stay smooth for everyone.
          </body>
        </div>
        <div class="greetings-box" :style="{ width: windowWidth + 'px' }" ref="messageBoxMobile">
          <h3>Greetings from the hotel</h3>
          <body :style="{ width: parseInt(windowWidth) - 48 + 'px' }" style="max-height: 143px; height: fit-content; padding: 24px">
            {{ messageToGroup }}
          </body>
          <div class="bottom-message-box" v-if="isOverflowingMobile">
            <div class="read-all" @click="showFullGreeting = true">
              <u>Read all</u>
              <img src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/arrow_1_5px/right.png" />
            </div>
          </div>
        </div>
        <div class="group-info-box" :style="{ width: windowWidth + 'px' }">
          <h3 style="margin-bottom: 16px">Group information</h3>
          <div class="sub-row">
            <body>
              Reservation owner
            </body>
            <b>{{ mainGuestName }}</b>
          </div>
          <div class="sub-row">
            <body>
              Email
            </body>
            <b>{{ mainGuestEmail }}</b>
          </div>
          <div class="sub-row">
            <body>
              Phone number
            </body>
            <b>{{ mainGuestPhone }}</b>
          </div>
          <div class="sub-row">
            <body>
              number of reservations
            </body>
            <b>{{ numberOfReservations }}</b>
          </div>
          <div class="sub-row">
            <body>
              Complete before
            </body>
            <b>{{ expireDate }}</b>
          </div>
        </div>
      </div>
      <div
        v-if="showFullGreeting"
        @click="showFullGreeting = false"
        style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; position: absolute; z-index: 100; top: 0; left: 0; background: linear-gradient(180deg, rgba(34, 34, 34, 0.35) 0%, rgba(34, 34, 34, 0.35) 100%); backdrop-filter: blur(2px)">
        <div class="greetings-full-box" @click.stop>
          <div class="greetings-header" :style="{ width: windowWidth + 'px' }">
            <div style="width: 16px; height: 16px"></div>
            <b>Greetings from the hotel</b>
            <img src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/cross.svg" @click="showFullGreeting = false" />
          </div>
          <div class="guest-message" :style="{ width: parseInt(windowWidth) - 16 + 'px' }">
            <body v-html="messageToGroup"></body>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import HotelV2Service from "@/api/Hotel/Hotelv2/HotelV2Service"
import JourneyV3Service from "@/api/Hotel/JourneyV3/JourneyV3Service"
import { groupBookingInt } from "@/api/Hotel/JourneyV3/Models/group-booking"
import moment from "moment"
import { Options, Vue } from "vue-class-component"
import localstorage from "@/store/localstorage"
import FloatingBottomBar from "@/components/FloatingBottomBar.vue"

@Options({
  components: {
    FloatingBottomBar,
  },
})
export default class WelcomePageView extends Vue {
  hotelName = ""
  groupId = ""
  hotelId = ""
  isMobile = false
  GroupBookingList: groupBookingInt[] = []
  mainGuestName = ""
  messageToGroup = ""
  mainGuestEmail = ""
  mainGuestPhone = ""
  numberOfReservations = 0
  expireDate = ""
  windowWidth = "200"
  hotelImage = ""
  isOverflowing = false
  isOverflowingMobile = false
  showFullGreeting = false

  async mounted() {
    this.groupId = this.$route.params.groupId as string
    this.hotelId = this.$route.params.hotelId as string
    localstorage.saveHotelId(this.hotelId)
    this.onResize()
    window.addEventListener("resize", this.onResize, { passive: true })

    await this.getHotel(this.hotelId)
    await this.getGroupBooking(this.hotelId, this.groupId)
    this.checkOverflow()
  }

  checkOverflow() {
    const div = this.$refs.messageBox as HTMLDivElement
    const divMobile = this.$refs.messageBoxMobile as HTMLDivElement
    if (!this.isMobile) this.isOverflowing = div.clientHeight < div.scrollHeight
    else this.isOverflowingMobile = divMobile.clientHeight < divMobile.scrollHeight
  }

  continueToNextPage() {
    var link = "/reservationlist/" + this.groupId + "/" + this.hotelId
    this.$router.push(link)
  }

  onResize() {
    const mediaQuery = window.matchMedia("(max-width: 520px)")
    this.windowWidth = (window.innerWidth - 32).toString()
    this.isMobile = mediaQuery.matches
  }

  async getHotel(hotelId: string) {
    try {
      var temp
      temp = await HotelV2Service.GetHotel(hotelId)
      this.hotelName = temp.Name
      this.hotelImage = temp.HotelImages[0].ImageUrl
    } catch (error) {
      console.error(error)
    }
  }

  async getGroupBooking(hotelId: string, groupId: string) {
    try {
      this.GroupBookingList = await JourneyV3Service.GetGroupBookingByGroupId(hotelId, groupId)
      this.mainGuestName = this.GroupBookingList[0]?.MainGuestName
      this.mainGuestEmail = this.GroupBookingList[0]?.MainGuestEmail
      this.mainGuestPhone = this.GroupBookingList[0]?.MainGuestPhoneNumber
      this.numberOfReservations = this.GroupBookingList.length
      this.expireDate = moment(this.GroupBookingList[0]?.ExpiryDate).format("MMM D YYYY · HH:mm [CET]")
      this.messageToGroup = this.GroupBookingList[0]?.CustomMessage

      if (moment(this.GroupBookingList[0]?.ExpiryDate) < moment() || this.GroupBookingList.length === 0) {
        this.$router.push("/LinkExpired")
        return
      }
    } catch (error) {
      console.error(error)
      return
    }
  }
}
</script>

<style scoped lang="scss">
body {
  background-color: transparent !important;
}
.MainPageMobile {
  position: absolute;
  top: 0;
  left: 0;
  position: scroll;
  h3 {
    color: $ag-black;
    font-family: $font;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
  }
  h4 {
    color: $ag-black;
    font-family: $font;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
  }
  h5 {
    color: $ag-black;
    font-family: $font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
  }
  b {
    color: $ag-black;
    font-family: $font;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0 !important;
  }
  body {
    text-align: left;
    color: $ag-black;
    font-family: $font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0 !important;
  }
  .title {
    color: $ag-black;
    font-family: $font;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin: 0;
  }
  .top-information {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .greetings-box {
    padding-top: 24px;
    border-radius: 12px;
    height: 224px;
    background-color: $ag-white;
    overflow: hidden;
    position: relative;
    box-shadow: 1px 1px 6px 0px rgba(34, 34, 34, 0.08);
    .bottom-message-box {
      position: absolute;
      width: 100%;
      height: 56px;
      z-index: 2;
      background-color: $ag-white;
      border-top: 1px solid $ag-light-grey;
      box-shadow: 0px -1px 0px 0px rgba(34, 34, 34, 0.1);
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      .read-all {
        color: $ag-black;
        font-family: $font;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 16px;
        display: flex;
        align-items: center;
        gap: 8px;

        img {
          width: 20px !important;
          height: 20px !important;
          box-shadow: none;
        }
      }
    }
  }
  .group-info-box {
    padding: 24px 0;
    border-radius: 12px;
    background-color: $ag-white;
    box-shadow: 1px 1px 6px 0px rgba(34, 34, 34, 0.08);
    margin-bottom: 120px;
    .sub-row {
      display: flex;
      justify-content: space-between;
      padding: 8px 24px;
    }
  }
  .greetings-full-box {
    width: 100%;
    height: 80%;
    border-radius: 12px;
    background-color: $ag-white;
    position: fixed;
    bottom: 150px;
    overflow: hidden;

    .greetings-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $ag-light-grey;
      padding: 16px;

      img {
        padding: 4px;
        border-radius: 12px;
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
      img:hover {
        background-color: $ag-cream;
      }
    }
    .guest-message {
      overflow: hidden;
      height: 195px;
      padding: 24px;
    }
  }
}

.MainPagePc {
  width: 100%;
  height: 100vh;
  position: absolute;
  background: #fafafa;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  h3 {
    color: $ag-black;
    font-family: $font;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
  }
  h4 {
    color: $ag-black;
    font-family: $font;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
  }
  h5 {
    color: $ag-black;
    font-family: $font;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
  }
  body {
    text-align: left;
    color: $ag-black;
    font-family: $font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0 !important;
  }
  .title {
    color: $ag-black;
    font-family: $font;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    margin: 0;
  }
  .button {
    display: flex;
    width: 170px;
    height: 24px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background-color: $ag-black;
    gap: 8px;
    flex-shrink: 0;
    color: $ag-white;
    font-family: $font;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    box-shadow: 1px 1px 6px 0px rgba(34, 34, 34, 0.08);
    cursor: pointer;
    img {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(282deg) brightness(105%) contrast(101%);
    }
  }
  .button:hover {
    opacity: 0.8;
  }

  .greetings-full-box {
    width: 620px;
    height: 340px;
    border-radius: 12px;
    background-color: $ag-white;

    .greetings-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 588px;
      border-bottom: 1px solid $ag-light-grey;
      padding: 16px;

      img {
        padding: 4px;
        border-radius: 12px;
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
      img:hover {
        background-color: $ag-cream;
      }
    }
    .guest-message {
      overflow-y: scroll;
      overflow-x: hidden;
      width: 540px;
      height: 195px;
      padding: 40px;
    }
    ::-webkit-scrollbar {
      width: 8px;
      flex-shrink: 0;
      border-radius: 12px;
    }

    ::-webkit-scrollbar-thumb {
      background: $ag-medium-grey;
      height: 33px;
      border-radius: 100px;
    }

    ::-webkit-scrollbar-track {
      background: $ag-cream;
    }
  }

  .left-info {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 200px 0 80px 80px;
    box-sizing: border-box;
  }
  .right-info {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 120px 80px 80px 0;
    box-sizing: border-box;
    gap: 16px;
    box-sizing: border-box;

    .image-row {
      display: flex;
      gap: 16px;
      width: 100%;

      img {
        width: 220px;
        height: 252px;
        border-radius: 16px;
        box-shadow: 1px 1px 6px 0px rgba(34, 34, 34, 0.08);
      }
    }

    .message-box {
      text-align: left;
      background-color: $ag-white;
      border-radius: 16px;
      width: 340px;
      height: 188px;
      padding: 32px;
      box-shadow: 1px 1px 6px 0px rgba(34, 34, 34, 0.08);
      overflow: hidden;
      position: relative;
    }

    .bottom-message-box {
      position: absolute;
      width: 100%;
      height: 56px;
      z-index: 2;
      background-color: $ag-white;
      border-top: 1px solid $ag-light-grey;
      box-shadow: 0px -1px 0px 0px rgba(34, 34, 34, 0.1);
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: flex-end;
      .read-all {
        cursor: pointer;
        color: $ag-black;
        font-family: $font;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 16px;
        display: flex;
        align-items: center;
        gap: 8px;

        img {
          width: 20px !important;
          height: 20px !important;
          box-shadow: none;
        }
      }
    }

    .information-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      background-color: $ag-white;
      border-radius: 16px;
      width: 592px;
      height: 232px;
      padding: 24px;
      box-shadow: 1px 1px 6px 0px rgba(34, 34, 34, 0.08);
      .sub-row {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }
}
</style>
