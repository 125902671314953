import BaseApiService from "@/api/BaseApiService"
import proxy from "@/api/proxy"
import { pmsAndResNumbers } from "./Models/Get-Group-Booking-Request"
import { groupBookingInt } from "./Models/group-booking"
import { UpdateGroupBookingAndJourney } from "./Models/Update-Journeys-request"

const JourneyV3Service = {
  GetGroupBookingByGroupId(hotelId: string, groupId: string): Promise<groupBookingInt[]> {
    return BaseApiService.getAsync(proxy.HotelV3, `Journey/Hotels/${hotelId}/GroupBooking/GetByGroupId?groupId=${groupId}`)
  },
  GetJourneysByListOfPms(hotelId: string, pmsAndResNumbers: pmsAndResNumbers[]) {
    return BaseApiService.postAsync(proxy.HotelV3, `Journey/Hotels/GetJourneysByListOfPms?hotelId=${hotelId}`, pmsAndResNumbers)
  },
  PutJourneyDataFromGroupBooking(bookingToBeUpdated: UpdateGroupBookingAndJourney) {
    return BaseApiService.putAsync(proxy.HotelV3, `Journey/Hotels/PutJourneyDataFromGroupBooking`, bookingToBeUpdated)
  },
  DeleteJourneyFromGroupBooking(HotelId: string, employeeName: string, pmsAndResNumbers: pmsAndResNumbers[]) {
    return BaseApiService.deleteAsync(proxy.HotelV3, `Journey/Hotels/${HotelId}/GroupBooking/Delete?employeeName=${employeeName}`, pmsAndResNumbers)
  },
}

export default JourneyV3Service
