<template>
  <router-view />
  <h1 v-if="$route.path === '/'">You may close the window</h1>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  background-color: #fafafa;
}
h1 {
  color: $ag-black;
  font-family: $font;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
  text-align: center;
  widows: 100vw;
}
</style>
