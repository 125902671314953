import { createStore } from "vuex"

export default createStore({
  state: {
    journeyList: [],
  },
  getters: {},
  mutations: {
    updateData(state, newJourneyList) {
      state.journeyList = newJourneyList
    },
  },
  actions: {
    setData({ commit }, newJourneyList) {
      commit("updateJourneyList", newJourneyList)
    },
  },
  modules: {},
})
