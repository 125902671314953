<template>
  <div class="whole-page-mobile" v-if="isMobile">
    <div v-if="type == 'succes'" class="succes">
      <img src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.5/check.svg" class="succes-filter" />
      <p>{{ text }}</p>
      <img class="X" src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/cross.svg" v-on:click="clearToast()" />
    </div>

    <div v-if="type == 'error'" class="error">
      <img src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.5/cross.svg" class="error-filter" />
      <p>{{ text }}</p>
      <img class="X" src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/cross.svg" v-on:click="clearToast()" />
    </div>
  </div>
  <div class="whole-page" v-if="!isMobile">
    <div v-if="type == 'succes'" class="succes">
      <img src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.5/check.svg" class="succes-filter" />
      <p>{{ text }}</p>
      <img class="X" src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/cross.svg" v-on:click="clearToast()" />
    </div>

    <div v-if="type == 'error'" class="error">
      <img src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.5/cross.svg" class="error-filter" />
      <p>{{ text }}</p>
      <img class="X" src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/cross.svg" v-on:click="clearToast()" />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"

@Options({
  props: {
    text: String,
    type: String,
    isMobile: Boolean,
  },
})
export default class Toaster extends Vue {
  text!: string
  type!: string
  isMobile!: boolean

  async mounted() {
    await new Promise((resolve) => setTimeout(resolve, 5000))
    this.clearToast()
  }

  clearToast() {
    this.$emit("updateToast")
  }
}
</script>

<style lang="scss">
p {
  color: $ag-black;
  font-family: $font;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
}

.whole-page-mobile {
  width: 90%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  padding: 5%;
  z-index: 5;
  top: 0;
  left: 0;
  .succes {
    background: $ag-white;
    width: 90%;
    padding: 16px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 12px;
    border-radius: 16px;
    border: 1px solid $ag-green;
    box-shadow: 1px 2px 10px 0px rgba(34, 34, 34, 0.05);
    .succes-filter {
      filter: invert(58%) sepia(42%) saturate(489%) hue-rotate(110deg) brightness(101%) contrast(91%);
    }
  }
  .error {
    background: $ag-white;
    width: 90%;
    padding: 16px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 12px;
    border-radius: 16px;
    border: 1px solid $ag-red;
    box-shadow: 1px 2px 10px 0px rgba(34, 34, 34, 0.05);
    .error-filter {
      filter: invert(76%) sepia(63%) saturate(6762%) hue-rotate(326deg) brightness(97%) contrast(85%);
    }
  }
}
.whole-page {
  display: flex;
  align-items: start;
  justify-content: flex-end;
  padding: 32px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 5;
  top: 0;

  .X {
    border-radius: 12px;
    cursor: pointer;
  }
  .X:hover {
    background: $ag-cream;
  }
  .succes {
    background: $ag-white;
    width: 343px;
    padding: 16px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 12px;
    border-radius: 16px;
    border: 1px solid $ag-green;
    box-shadow: 1px 2px 10px 0px rgba(34, 34, 34, 0.05);
    .succes-filter {
      filter: invert(58%) sepia(42%) saturate(489%) hue-rotate(110deg) brightness(101%) contrast(91%);
    }
  }
  .error {
    background: $ag-white;
    width: 343px;
    padding: 16px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 12px;
    border-radius: 16px;
    border: 1px solid $ag-red;
    box-shadow: 1px 2px 10px 0px rgba(34, 34, 34, 0.05);
    .error-filter {
      filter: invert(76%) sepia(63%) saturate(6762%) hue-rotate(326deg) brightness(97%) contrast(85%);
    }
  }
}
</style>
